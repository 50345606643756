/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the PointDto interface.
 */
export function instanceOfPointDto(value) {
    if (!('latitude' in value) || value['latitude'] === undefined)
        return false;
    if (!('longitude' in value) || value['longitude'] === undefined)
        return false;
    return true;
}
export function PointDtoFromJSON(json) {
    return PointDtoFromJSONTyped(json, false);
}
export function PointDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'latitude': json['latitude'],
        'longitude': json['longitude'],
    };
}
export function PointDtoToJSON(json) {
    return PointDtoToJSONTyped(json, false);
}
export function PointDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'latitude': value['latitude'],
        'longitude': value['longitude'],
    };
}
