/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { JobClosureReasonFromJSON, JobClosureReasonToJSON, } from './JobClosureReason.js';
/**
 * Check if a given object implements the CloseJobCommandDto interface.
 */
export function instanceOfCloseJobCommandDto(value) {
    if (!('jobId' in value) || value['jobId'] === undefined)
        return false;
    if (!('reason' in value) || value['reason'] === undefined)
        return false;
    return true;
}
export function CloseJobCommandDtoFromJSON(json) {
    return CloseJobCommandDtoFromJSONTyped(json, false);
}
export function CloseJobCommandDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'jobId': json['jobId'],
        'reason': JobClosureReasonFromJSON(json['reason']),
        'closureMessage': json['closureMessage'] == null ? undefined : json['closureMessage'],
    };
}
export function CloseJobCommandDtoToJSON(json) {
    return CloseJobCommandDtoToJSONTyped(json, false);
}
export function CloseJobCommandDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'jobId': value['jobId'],
        'reason': JobClosureReasonToJSON(value['reason']),
        'closureMessage': value['closureMessage'],
    };
}
