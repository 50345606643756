/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PermissionDtoFromJSON, PermissionDtoToJSON, } from './PermissionDto.js';
import { EmployerRoleFromJSON, EmployerRoleToJSON, } from './EmployerRole.js';
/**
 * Check if a given object implements the GetEmployerAccessResult interface.
 */
export function instanceOfGetEmployerAccessResult(value) {
    if (!('canAccessOtherCompanies' in value) || value['canAccessOtherCompanies'] === undefined)
        return false;
    if (!('permissions' in value) || value['permissions'] === undefined)
        return false;
    if (!('roles' in value) || value['roles'] === undefined)
        return false;
    return true;
}
export function GetEmployerAccessResultFromJSON(json) {
    return GetEmployerAccessResultFromJSONTyped(json, false);
}
export function GetEmployerAccessResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'canAccessOtherCompanies': json['canAccessOtherCompanies'],
        'permissions': (json['permissions'].map(PermissionDtoFromJSON)),
        'roles': (json['roles'].map(EmployerRoleFromJSON)),
    };
}
export function GetEmployerAccessResultToJSON(json) {
    return GetEmployerAccessResultToJSONTyped(json, false);
}
export function GetEmployerAccessResultToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'canAccessOtherCompanies': value['canAccessOtherCompanies'],
        'permissions': (value['permissions'].map(PermissionDtoToJSON)),
        'roles': (value['roles'].map(EmployerRoleToJSON)),
    };
}
