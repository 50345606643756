import type { RouteLocationNormalized } from 'vue-router'
import type { GuardResult } from '@/guards/abstract-guard'
import { Guard, GuardResultType } from '@/guards/abstract-guard'

/**
 * Guard to check if the user is authenticated
 */
export default class AuthenticationGuard extends Guard {
  protected label = 'AuthenticationGuard'

  protected async check(to: RouteLocationNormalized): Promise<GuardResult | void> {
    const isAuthRedirect = to.meta.isAuthRedirect
    if (isAuthRedirect) {
      return GuardResultType.Skip
    }

    const isAuthenticated = await this.authStore.isAuthenticated()
    if (!isAuthenticated) {
      if (!to.meta.allowAnonymous) {
        return {
          name: '/login',
        }
      }

      return GuardResultType.Skip
    }

    const usedAuthProvider = this.authStore.authService?.authProvider === to.meta.requiredAuthProvider
    if (to.meta.requiredAuthProvider && !usedAuthProvider) {
      return GuardResultType.Skip
    }
  }
}
