/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChatMessageDtoFromJSON, ChatMessageDtoToJSON, } from './ChatMessageDto.js';
import { ConferenceChatDtoFromJSON, ConferenceChatDtoToJSON, } from './ConferenceChatDto.js';
/**
 * Check if a given object implements the GetConferenceChatWithMessagesForEmployerResult interface.
 */
export function instanceOfGetConferenceChatWithMessagesForEmployerResult(value) {
    if (!('chat' in value) || value['chat'] === undefined)
        return false;
    if (!('messages' in value) || value['messages'] === undefined)
        return false;
    return true;
}
export function GetConferenceChatWithMessagesForEmployerResultFromJSON(json) {
    return GetConferenceChatWithMessagesForEmployerResultFromJSONTyped(json, false);
}
export function GetConferenceChatWithMessagesForEmployerResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'chat': ConferenceChatDtoFromJSON(json['chat']),
        'messages': (json['messages'].map(ChatMessageDtoFromJSON)),
    };
}
export function GetConferenceChatWithMessagesForEmployerResultToJSON(json) {
    return GetConferenceChatWithMessagesForEmployerResultToJSONTyped(json, false);
}
export function GetConferenceChatWithMessagesForEmployerResultToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'chat': ConferenceChatDtoToJSON(value['chat']),
        'messages': (value['messages'].map(ChatMessageDtoToJSON)),
    };
}
