/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Permission level for a resource
 * @export
 */
export const PermissionLevel = {
    Read: 'Read',
    Write: 'Write',
    Delete: 'Delete'
};
export function instanceOfPermissionLevel(value) {
    for (const key in PermissionLevel) {
        if (Object.prototype.hasOwnProperty.call(PermissionLevel, key)) {
            if (PermissionLevel[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function PermissionLevelFromJSON(json) {
    return PermissionLevelFromJSONTyped(json, false);
}
export function PermissionLevelFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function PermissionLevelToJSON(value) {
    return value;
}
export function PermissionLevelToJSONTyped(value, ignoreDiscriminator) {
    return value;
}
