import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

// Small wrapper around useBreakpoints to provide presets and more readable names
export function useResponsive() {
  const breakpoints = useBreakpoints(breakpointsTailwind)
  const isMobile = breakpoints.smaller('lg')
  const isDesktop = breakpoints.greaterOrEqual('lg')
  const isLargeScreen = breakpoints.greaterOrEqual('xl')
  const isVeryLargeScreen = breakpoints.greaterOrEqual('2xl')

  return {
    isMobile,
    isDesktop,
    isLargeScreen,
    isVeryLargeScreen,
  }
}
