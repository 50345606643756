/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ReactToCandidateCommandDto interface.
 */
export function instanceOfReactToCandidateCommandDto(value) {
    if (!('matchScoreId' in value) || value['matchScoreId'] === undefined)
        return false;
    if (!('toLike' in value) || value['toLike'] === undefined)
        return false;
    return true;
}
export function ReactToCandidateCommandDtoFromJSON(json) {
    return ReactToCandidateCommandDtoFromJSONTyped(json, false);
}
export function ReactToCandidateCommandDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'matchScoreId': json['matchScoreId'],
        'toLike': json['toLike'],
    };
}
export function ReactToCandidateCommandDtoToJSON(json) {
    return ReactToCandidateCommandDtoToJSONTyped(json, false);
}
export function ReactToCandidateCommandDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'matchScoreId': value['matchScoreId'],
        'toLike': value['toLike'],
    };
}
