<script setup lang="ts">
import { PermissionResource } from '@swipe4work/api-client-fetch'
import { storeToRefs } from 'pinia'
import { useConferenceChatStore } from '../conference-chat-store'
import { useTopbarStore } from '@/components/app/topbar-store'

definePage({
  path: '/bezoekers/:id',
  meta: {
    permissionResource: PermissionResource.ConferenceSwipe,
  },
})

const route = useRoute('/conference/swipes/[id]')
const chatId = Number.parseInt(route.params.id)
const conferenceChatStore = useConferenceChatStore()

conferenceChatStore.fetchChat(chatId)
const { chat, messages } = storeToRefs(conferenceChatStore)
const isLoading = computed(() => chat.value === undefined)

interface MessageInput {
  body: string
  isValid: boolean
  sendMessage: () => void
  updateMessage: (message: string | undefined) => void
}

const primeMessageInput = ref<any>()

const messageInput = ref<MessageInput>({
  body: '',
  isValid: true,
  sendMessage: () => {
    const messageBody = messageInput.value.body.trim()
    if (!messageBody) {
      messageInput.value.isValid = false
      refocusOnMessageInput()

      return
    }
    messageInput.value.body = ''
    conferenceChatStore.sendMessage(chatId, messageBody)
  },
  updateMessage: (message: string | undefined) => {
    messageInput.value.body = message ?? ''
    if (!messageInput.value.isValid) {
      messageInput.value.isValid = true
      refocusOnMessageInput()
    }
  },
})

const chatBox = ref<any>(null)
// hooks to let the chat work collectively, like fetching messages, (un)subscribing to chat, etc.

onMounted(async () => {
  await conferenceChatStore.subscribe(chatId)
  // small timeout needed
  setTimeout(() => scrollToBottom(), 100)
})

onBeforeUnmount(async () => {
  await conferenceChatStore.unsubscribe(chatId)
})

// pinia store doesn't provide a way to subscribe to a ref in production mode, so we need to keep track of the message length
// watch on messages does also not work, so we have to subscribe to the store and check if the length has changed
const messageLengthRef = ref(messages.value.length)

// make sure to scroll to the bottom when a new message is added; either employee or employer
conferenceChatStore.$subscribe(() => {
  if (messageLengthRef.value === messages.value.length) {
    return
  }

  messageLengthRef.value = messages.value.length

  scrollToBottom()
})

function refocusOnMessageInput(): void {
  nextTick(() => {
    primeMessageInput.value.$el.blur()
    primeMessageInput.value.$el.focus()
  })
}

function scrollToBottom(): void {
  if (chatBox.value && chatBox.value.$refs.content) {
    nextTick(() => {
      const scrollableHeight = chatBox.value.$refs.content.scrollHeight - chatBox.value.$refs.content.clientHeight
      chatBox.value.scrollTop(scrollableHeight)
    })
  }
}

const t = useI18n()
const topbarStore = useTopbarStore()
watch(chat, () => {
  if (chat.value) {
    topbarStore.set({
      breadcrumbs: [
        { label: t.t('routes.conferenceChats'), route: { name: '/conference/swipes/' } },
        { label: t.t('matchDetail.title', { firstName: chat.value.info.otherParticipant.displayName }) },
      ],
    })
  }
}, { immediate: true })
</script>

<template>
  <div class="h-100%">
    <div class="h-[calc(100vh-7rem)] flex gap-x-4">
      <template v-if="isLoading">
        <Skeleton width="100%" height="100%" />
      </template>
      <div v-else class="card flex flex-1 flex-col !pt-0">
        <ScrollPanel
          ref="chatBox" class="h-1 flex-1 pb-2"
        >
          <div class="flex flex-col">
            <ChatMessage v-for="(message, i) in messages" :key="i" :message="message" />
          </div>
        </ScrollPanel>

        <!-- Input box for sending text -->
        <div class="w-100% flex items-center justify-end">
          <i
            class="bi bi-send absolute mr-4 cursor-pointer text-xl"
            @click="messageInput.sendMessage()"
          />
          <InputText
            ref="primeMessageInput"
            v-tooltip.focus.top="{ value: !messageInput.isValid ? `${$t('chat.messageEmpty')}` : '' }"
            autofocus
            :class="{ 'p-invalid': !messageInput.isValid }"
            :model-value="messageInput.body"
            class="w-100% bg-slate-50 pr-12 text-xl shadow-sm"
            type="text"

            @update:model-value="messageInput.updateMessage($event)"
            @keyup.enter="messageInput.sendMessage()"
          />
        </div>
      </div>
    </div>
  </div>
</template>
