/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the DecimalRangeDto interface.
 */
export function instanceOfDecimalRangeDto(value) {
    if (!('lower' in value) || value['lower'] === undefined)
        return false;
    if (!('upper' in value) || value['upper'] === undefined)
        return false;
    return true;
}
export function DecimalRangeDtoFromJSON(json) {
    return DecimalRangeDtoFromJSONTyped(json, false);
}
export function DecimalRangeDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'lower': json['lower'],
        'upper': json['upper'],
    };
}
export function DecimalRangeDtoToJSON(json) {
    return DecimalRangeDtoToJSONTyped(json, false);
}
export function DecimalRangeDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'lower': value['lower'],
        'upper': value['upper'],
    };
}
