import type { App } from 'vue'
import type { Configuration, ResponseError } from '@swipe4work/api-client-fetch'
import { EmployerApi, GeneralApi } from '@swipe4work/api-client-fetch'
import { apiClientFetchConfiguration } from '@/configs/client-configs'

export interface HttpClients {
  employerApi: EmployerApi
  generalApi: GeneralApi
}

const employerApiInjectionKey = Symbol('employerApi')
const generalApiInjectionKey = Symbol('generalApi')

export function useEmployerApi() {
  const employerApi = inject(employerApiInjectionKey) as (config: Configuration) => EmployerApi

  return employerApi(apiClientFetchConfiguration())
}

export function useGeneralApi() {
  const generalApi = inject(generalApiInjectionKey) as (config: Configuration) => GeneralApi

  return generalApi(apiClientFetchConfiguration())
}

export async function hasErrorCode(err: ResponseError, errorCode: number): Promise<boolean> {
  // Parse the HTTP response and check if the errorMap has the given error code
  const errors = (await err.response.json()).errors
  if (!errors) {
    return false
  }

  const hasErrorCode = Object.prototype.hasOwnProperty.call(errors, errorCode)
  return hasErrorCode
}

export default {
  install(app: App): void {
    const employerApi = (config: Configuration) => new EmployerApi(config)
    const generalApi = (config: Configuration) => new GeneralApi(config)

    app.provide(employerApiInjectionKey, employerApi)
    app.provide(generalApiInjectionKey, generalApi)
  },
}
