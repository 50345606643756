/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DefaultMessageTypeFromJSON, DefaultMessageTypeToJSON, } from './DefaultMessageType.js';
/**
 * Check if a given object implements the DefaultMessageDto interface.
 */
export function instanceOfDefaultMessageDto(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('title' in value) || value['title'] === undefined)
        return false;
    if (!('body' in value) || value['body'] === undefined)
        return false;
    if (!('isPublic' in value) || value['isPublic'] === undefined)
        return false;
    if (!('messageType' in value) || value['messageType'] === undefined)
        return false;
    return true;
}
export function DefaultMessageDtoFromJSON(json) {
    return DefaultMessageDtoFromJSONTyped(json, false);
}
export function DefaultMessageDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'title': json['title'],
        'body': json['body'],
        'isPublic': json['isPublic'],
        'messageType': DefaultMessageTypeFromJSON(json['messageType']),
    };
}
export function DefaultMessageDtoToJSON(json) {
    return DefaultMessageDtoToJSONTyped(json, false);
}
export function DefaultMessageDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'title': value['title'],
        'body': value['body'],
        'isPublic': value['isPublic'],
        'messageType': DefaultMessageTypeToJSON(value['messageType']),
    };
}
