/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EmployerMatchClosureReasonFromJSON, EmployerMatchClosureReasonToJSON, } from './EmployerMatchClosureReason.js';
/**
 * Check if a given object implements the GetMatchChatWithMessagesMatchStateDto interface.
 */
export function instanceOfGetMatchChatWithMessagesMatchStateDto(value) {
    if (!('suggestedAndHasNegativeCredits' in value) || value['suggestedAndHasNegativeCredits'] === undefined)
        return false;
    if (!('isClosed' in value) || value['isClosed'] === undefined)
        return false;
    return true;
}
export function GetMatchChatWithMessagesMatchStateDtoFromJSON(json) {
    return GetMatchChatWithMessagesMatchStateDtoFromJSONTyped(json, false);
}
export function GetMatchChatWithMessagesMatchStateDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'suggestedAndHasNegativeCredits': json['suggestedAndHasNegativeCredits'],
        'isClosed': json['isClosed'],
        'closedAt': json['closedAt'] == null ? undefined : (new Date(json['closedAt'])),
        'closureReason': json['closureReason'] == null ? undefined : EmployerMatchClosureReasonFromJSON(json['closureReason']),
    };
}
export function GetMatchChatWithMessagesMatchStateDtoToJSON(json) {
    return GetMatchChatWithMessagesMatchStateDtoToJSONTyped(json, false);
}
export function GetMatchChatWithMessagesMatchStateDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'suggestedAndHasNegativeCredits': value['suggestedAndHasNegativeCredits'],
        'isClosed': value['isClosed'],
        'closedAt': value['closedAt'] == null ? undefined : (value['closedAt'].toISOString()),
        'closureReason': EmployerMatchClosureReasonToJSON(value['closureReason']),
    };
}
