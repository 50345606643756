import * as Sentry from '@sentry/vue'
import { browserTracingIntegration } from '@sentry/vue'
import type { UserModule } from '@/types'

export const install: UserModule = (app, router) => {
  if (import.meta.env.VITE_APP_SENTRY_ENVIRONMENT) {
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_APP_SENTRY_DSN_URL,
      tunnel: import.meta.env.VITE_APP_SENTRY_TUNNEL,
      integrations: [
        browserTracingIntegration({ router }),
      ],
      tracePropagationTargets: [
        'localhost',
        'portal.swipe4work.com',
        'portal.test.swipe4work.com',
        'api.swipe4work.com',
        'api.test.swipe4work.com',
        /^\//,
      ],
      logErrors: import.meta.env.VITE_APP_SENTRY_ENVIRONMENT !== 'production', // Log errors to console in non-production environments
      release: `portal@${import.meta.env.GIT_SHA}`,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: import.meta.env.VITE_APP_SENTRY_SAMPLE_RATE,
      environment: import.meta.env.VITE_APP_SENTRY_ENVIRONMENT,
    })
  }
}
