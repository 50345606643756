/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { WorkExperienceTypeFromJSON, WorkExperienceTypeToJSON, } from './WorkExperienceType.js';
/**
 * Check if a given object implements the WorkExperienceDto interface.
 */
export function instanceOfWorkExperienceDto(value) {
    if (!('functionTitle' in value) || value['functionTitle'] === undefined)
        return false;
    if (!('company' in value) || value['company'] === undefined)
        return false;
    if (!('startDate' in value) || value['startDate'] === undefined)
        return false;
    return true;
}
export function WorkExperienceDtoFromJSON(json) {
    return WorkExperienceDtoFromJSONTyped(json, false);
}
export function WorkExperienceDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'type': json['type'] == null ? undefined : WorkExperienceTypeFromJSON(json['type']),
        'functionTitle': json['functionTitle'],
        'company': json['company'],
        'startDate': (new Date(json['startDate'])),
        'endDate': json['endDate'] == null ? undefined : (new Date(json['endDate'])),
    };
}
export function WorkExperienceDtoToJSON(json) {
    return WorkExperienceDtoToJSONTyped(json, false);
}
export function WorkExperienceDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'type': WorkExperienceTypeToJSON(value['type']),
        'functionTitle': value['functionTitle'],
        'company': value['company'],
        'startDate': ((value['startDate']).toISOString().substring(0, 10)),
        'endDate': value['endDate'] == null ? undefined : (value['endDate'].toISOString().substring(0, 10)),
    };
}
