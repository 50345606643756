/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the UpdateJobPublishedCommandDto interface.
 */
export function instanceOfUpdateJobPublishedCommandDto(value) {
    if (!('jobId' in value) || value['jobId'] === undefined)
        return false;
    if (!('toPublish' in value) || value['toPublish'] === undefined)
        return false;
    return true;
}
export function UpdateJobPublishedCommandDtoFromJSON(json) {
    return UpdateJobPublishedCommandDtoFromJSONTyped(json, false);
}
export function UpdateJobPublishedCommandDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'jobId': json['jobId'],
        'toPublish': json['toPublish'],
    };
}
export function UpdateJobPublishedCommandDtoToJSON(json) {
    return UpdateJobPublishedCommandDtoToJSONTyped(json, false);
}
export function UpdateJobPublishedCommandDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'jobId': value['jobId'],
        'toPublish': value['toPublish'],
    };
}
