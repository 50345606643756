/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the PersonalityTypeDto interface.
 */
export function instanceOfPersonalityTypeDto(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('summary' in value) || value['summary'] === undefined)
        return false;
    if (!('energizers' in value) || value['energizers'] === undefined)
        return false;
    if (!('drainers' in value) || value['drainers'] === undefined)
        return false;
    return true;
}
export function PersonalityTypeDtoFromJSON(json) {
    return PersonalityTypeDtoFromJSONTyped(json, false);
}
export function PersonalityTypeDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'name': json['name'],
        'summary': json['summary'],
        'energizers': json['energizers'],
        'drainers': json['drainers'],
    };
}
export function PersonalityTypeDtoToJSON(json) {
    return PersonalityTypeDtoToJSONTyped(json, false);
}
export function PersonalityTypeDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'name': value['name'],
        'summary': value['summary'],
        'energizers': value['energizers'],
        'drainers': value['drainers'],
    };
}
