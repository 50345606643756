/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { JobFunctionCrudJobFunctionDtoFromJSON, JobFunctionCrudJobFunctionDtoToJSON, } from './JobFunctionCrudJobFunctionDto.js';
/**
 * Check if a given object implements the JobFunctionCrudCreateOrUpdateCommand interface.
 */
export function instanceOfJobFunctionCrudCreateOrUpdateCommand(value) {
    if (!('dto' in value) || value['dto'] === undefined)
        return false;
    return true;
}
export function JobFunctionCrudCreateOrUpdateCommandFromJSON(json) {
    return JobFunctionCrudCreateOrUpdateCommandFromJSONTyped(json, false);
}
export function JobFunctionCrudCreateOrUpdateCommandFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'dto': JobFunctionCrudJobFunctionDtoFromJSON(json['dto']),
    };
}
export function JobFunctionCrudCreateOrUpdateCommandToJSON(json) {
    return JobFunctionCrudCreateOrUpdateCommandToJSONTyped(json, false);
}
export function JobFunctionCrudCreateOrUpdateCommandToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'dto': JobFunctionCrudJobFunctionDtoToJSON(value['dto']),
    };
}
