/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReportingPeriodicityFromJSON, ReportingPeriodicityToJSON, } from './ReportingPeriodicity.js';
/**
 * Check if a given object implements the GetEmployerProfileResult interface.
 */
export function instanceOfGetEmployerProfileResult(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('externalId' in value) || value['externalId'] === undefined)
        return false;
    if (!('firstName' in value) || value['firstName'] === undefined)
        return false;
    if (!('lastName' in value) || value['lastName'] === undefined)
        return false;
    if (!('email' in value) || value['email'] === undefined)
        return false;
    if (!('reportingPeriodicity' in value) || value['reportingPeriodicity'] === undefined)
        return false;
    if (!('companyId' in value) || value['companyId'] === undefined)
        return false;
    if (!('companyName' in value) || value['companyName'] === undefined)
        return false;
    if (!('hasSubscription' in value) || value['hasSubscription'] === undefined)
        return false;
    return true;
}
export function GetEmployerProfileResultFromJSON(json) {
    return GetEmployerProfileResultFromJSONTyped(json, false);
}
export function GetEmployerProfileResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'externalId': json['externalId'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'reportingPeriodicity': ReportingPeriodicityFromJSON(json['reportingPeriodicity']),
        'companyId': json['companyId'],
        'companyName': json['companyName'],
        'originalCompanyId': json['originalCompanyId'] == null ? undefined : json['originalCompanyId'],
        'originalCompanyName': json['originalCompanyName'] == null ? undefined : json['originalCompanyName'],
        'hasSubscription': json['hasSubscription'],
    };
}
export function GetEmployerProfileResultToJSON(json) {
    return GetEmployerProfileResultToJSONTyped(json, false);
}
export function GetEmployerProfileResultToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'externalId': value['externalId'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'email': value['email'],
        'reportingPeriodicity': ReportingPeriodicityToJSON(value['reportingPeriodicity']),
        'companyId': value['companyId'],
        'companyName': value['companyName'],
        'originalCompanyId': value['originalCompanyId'],
        'originalCompanyName': value['originalCompanyName'],
        'hasSubscription': value['hasSubscription'],
    };
}
