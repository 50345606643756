import type { App, Plugin } from 'vue'
import Vue3Lottie from 'vue3-lottie'

export interface LottieStyleOptionsInterface {
  width?: string
  height?: string
  classes?: string
}

// had to do this because of the way the package is exported, it's not a default export so it can't be imported as default
const Vue3LottiePlugin: Plugin = {
  install(app: App, options: { name: string } = { name: 'Vue3Lottie' }) {
    // Register the component globally under the provided name or a default name
    app.component(options.name, Vue3Lottie)
  },
}

export default Vue3LottiePlugin
