/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MatchChatDtoFromJSON, MatchChatDtoToJSON, } from './MatchChatDto.js';
/**
 * Check if a given object implements the ListMatchesResult interface.
 */
export function instanceOfListMatchesResult(value) {
    if (!('count' in value) || value['count'] === undefined)
        return false;
    if (!('totalCount' in value) || value['totalCount'] === undefined)
        return false;
    if (!('matches' in value) || value['matches'] === undefined)
        return false;
    return true;
}
export function ListMatchesResultFromJSON(json) {
    return ListMatchesResultFromJSONTyped(json, false);
}
export function ListMatchesResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'count': json['count'],
        'totalCount': json['totalCount'],
        'matches': (json['matches'].map(MatchChatDtoFromJSON)),
    };
}
export function ListMatchesResultToJSON(json) {
    return ListMatchesResultToJSONTyped(json, false);
}
export function ListMatchesResultToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'count': value['count'],
        'totalCount': value['totalCount'],
        'matches': (value['matches'].map(MatchChatDtoToJSON)),
    };
}
