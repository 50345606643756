import type { JwtPayload as JwtBasePayload } from 'jwt-decode'
import jwt_decode from 'jwt-decode'

import { AuthState } from '@/store/auth'
import { AuthProvider, AuthService } from '@/services/auth/auth-service'

type JwtPayload = Partial<JwtBasePayload> & {
  [key: string]: any
}

export class JwtAuthService extends AuthService<JwtPayload, null> {
  authProvider = AuthProvider.Jwt

  private storageKey = 'jwt:accessToken'
  private static validIssuer = 'Swipe4WorkApi'

  /**
   * @inheritDoc
   */
  protected user = () => {
    if (!this.accessToken) {
      return null
    }

    return jwt_decode<JwtPayload>(this.accessToken)
  }

  /**
   * @inheritDoc
   */
  protected authClient = null

  /**
   * @inheritDoc
   */
  async init() {
    this.accessToken = localStorage.getItem(this.storageKey)
  }

  /**
   * @inheritDoc
   */
  async getAccessToken() {
    return this.accessToken
  }

  tokenDecoded() {
    if (!this.accessToken) {
      return null
    }

    return jwt_decode<JwtPayload>(this.accessToken)
  }

  /**
   * @inheritDoc
   */
  async isAuthenticated() {
    return JwtAuthService.validateToken(this.accessToken || '') === true
  }

  /**
   * @inheritDoc
   */
  async logout() {
    this.accessToken = null
    localStorage.removeItem(this.storageKey)

    return true
  }

  async loginWithToken(token: string): Promise<boolean> {
    if (JwtAuthService.validateToken(token) !== true) {
      return false
    }

    this.accessToken = token
    localStorage.setItem(this.storageKey, token)

    return true
  }

  static validateToken(token: string): AuthState | true {
    try {
      const payload = jwt_decode<Partial<JwtPayload>>(token)

      if (payload.iss !== JwtAuthService.validIssuer) {
        return AuthState.InvalidToken
      }

      if (!payload.exp || payload.exp < Date.now() / 1000) {
        return AuthState.ExpiredToken
      }

      return true
    } catch (e) {
      return AuthState.InvalidToken
    }
  }
}
