/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ParticipantDtoFromJSON, ParticipantDtoToJSON, } from './ParticipantDto.js';
/**
 * Check if a given object implements the ChatInfoDto interface.
 */
export function instanceOfChatInfoDto(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined)
        return false;
    if (!('unreadCount' in value) || value['unreadCount'] === undefined)
        return false;
    if (!('otherParticipant' in value) || value['otherParticipant'] === undefined)
        return false;
    if (!('isUserResponseMissing' in value) || value['isUserResponseMissing'] === undefined)
        return false;
    if (!('lastMessageBody' in value) || value['lastMessageBody'] === undefined)
        return false;
    if (!('lastMessageAt' in value) || value['lastMessageAt'] === undefined)
        return false;
    return true;
}
export function ChatInfoDtoFromJSON(json) {
    return ChatInfoDtoFromJSONTyped(json, false);
}
export function ChatInfoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'unreadCount': json['unreadCount'],
        'otherParticipant': ParticipantDtoFromJSON(json['otherParticipant']),
        'isUserResponseMissing': json['isUserResponseMissing'],
        'lastMessageBody': json['lastMessageBody'],
        'lastMessageAt': (new Date(json['lastMessageAt'])),
    };
}
export function ChatInfoDtoToJSON(json) {
    return ChatInfoDtoToJSONTyped(json, false);
}
export function ChatInfoDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'createdAt': ((value['createdAt']).toISOString()),
        'unreadCount': value['unreadCount'],
        'otherParticipant': ParticipantDtoToJSON(value['otherParticipant']),
        'isUserResponseMissing': value['isUserResponseMissing'],
        'lastMessageBody': value['lastMessageBody'],
        'lastMessageAt': ((value['lastMessageAt']).toISOString()),
    };
}
