<script setup lang="ts">
import { PermissionResource } from '@swipe4work/api-client-fetch'
import { useTopbarStore } from '@/components/app/topbar-store'

definePage({
  path: '/bezoekers',
  meta: {
    permissionResource: PermissionResource.ConferenceSwipe,
  },
})

const t = useI18n()

const topbarStore = useTopbarStore()

topbarStore.setAndClear({
  breadcrumbs: [{ label: t.t('routes.conferenceChats') }],
})
</script>

<template>
  <loading-wrapper>
    <ConferenceSwipeList />
  </loading-wrapper>
</template>
