/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the AddressDto interface.
 */
export function instanceOfAddressDto(value) {
    if (!('postalCode' in value) || value['postalCode'] === undefined)
        return false;
    if (!('addressLine' in value) || value['addressLine'] === undefined)
        return false;
    if (!('city' in value) || value['city'] === undefined)
        return false;
    return true;
}
export function AddressDtoFromJSON(json) {
    return AddressDtoFromJSONTyped(json, false);
}
export function AddressDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'postalCode': json['postalCode'],
        'addressLine': json['addressLine'],
        'city': json['city'],
    };
}
export function AddressDtoToJSON(json) {
    return AddressDtoToJSONTyped(json, false);
}
export function AddressDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'postalCode': value['postalCode'],
        'addressLine': value['addressLine'],
        'city': value['city'],
    };
}
