<script setup lang="ts">
import { object, string } from 'zod'
import { useFirebaseAuth } from 'vuefire'
import { useForm } from '@/modules/form'
import { useAuthStore } from '@/store'
import { FirebaseAuthService } from '@/services/auth/firebase-auth-service'
import AuthLayoutCard from '@/components/cards/AuthLayoutCard.vue'
import { Target } from '@/components/links/BasicTextLinkExternal.vue'
import AuthFormGroup from '@/components/auth/AuthFormGroup.vue'

const authStore = useAuthStore()
const firebaseAuth = useFirebaseAuth()
const router = useRouter()

definePage({
  name: '/login',
  path: '/login',
  meta: {
    allowAnonymous: true,
  },
})

const loginModel = reactive({
  email: '',
  password: '',
})

const loginScheme = object({
  email: string().min(1),
  password: string().min(1),
})

const form = useForm(loginModel, loginScheme)
const loginFailed = ref(false)

async function submit() {
  loginFailed.value = false
  const entity = form.validate()
  if (!entity) {
    return
  }

  const firebaseAuthService = await authStore.getAuthService(FirebaseAuthService, firebaseAuth)

  try {
    await firebaseAuthService.login(entity.email, entity.password)
  } catch (error) {
    loginFailed.value = true
    return
  }

  await router.push({ name: '/' })
}
</script>

<template>
  <AuthLayoutCard>
    <template #title>
      {{ $t("auth.login.title") }}
    </template>

    <form class="w-100% flex flex-col items-center justify-center gap-y-5 px-5">
      <AuthFormGroup :label="$t('email')">
        <InputText
          id="email"
          v-model.trim="loginModel.email"
          class="w-100%"
          required="true"
          type="email"
          autocomplete="email"
          autofocus
          :class="{ 'p-invalid': form.hasError('loginModel.email') }"
        />
      </AuthFormGroup>

      <AuthFormGroup :label="$t('password')" :error="loginFailed ? $t('auth.login.invalidCredentials') : undefined">
        <InputText
          id="current-password"
          v-model="loginModel.password"
          class="w-100%"
          required="true"
          type="password"
          autocomplete="current-password"
          :class="{ 'p-invalid': form.hasError('loginModel.password') }"
        />
      </AuthFormGroup>

      <Button v-click-async="submit" type="submit" rounded :label="$t('auth.login.submit')" icon-pos="left" icon="bi bi-box-arrow-in-right" />
    </form>

    <div class="flex flex-col items-center justify-center gap-y-1 text-sm">
      <span>
        {{ $t('auth.login.noAccount') }}
        <BasicTextLinkExternal :link="$t('auth.login.noAccountLink')" :label="$t('auth.login.contact')" :target="Target.Self" class="font-normal!" />
      </span>

      <span>
        {{ $t('auth.login.forgotPassword') }}
        <BasicTextLinkInternal :to="{ name: '/reset-password' }" :label="$t('auth.login.resetPassword')" class="font-normal!" />
      </span>
    </div>
  </AuthLayoutCard>
</template>
