/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DateTimeRangeDtoFromJSON, DateTimeRangeDtoToJSON, } from './DateTimeRangeDto.js';
/**
 * Check if a given object implements the ConferenceToCompanyDto interface.
 */
export function instanceOfConferenceToCompanyDto(value) {
    if (!('conferenceId' in value) || value['conferenceId'] === undefined)
        return false;
    if (!('date' in value) || value['date'] === undefined)
        return false;
    if (!('companyJobsVisibleWithinApp' in value) || value['companyJobsVisibleWithinApp'] === undefined)
        return false;
    if (!('conferenceModuleVisibleWithinApp' in value) || value['conferenceModuleVisibleWithinApp'] === undefined)
        return false;
    if (!('conferenceArea' in value) || value['conferenceArea'] === undefined)
        return false;
    if (!('standNumber' in value) || value['standNumber'] === undefined)
        return false;
    return true;
}
export function ConferenceToCompanyDtoFromJSON(json) {
    return ConferenceToCompanyDtoFromJSONTyped(json, false);
}
export function ConferenceToCompanyDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'conferenceId': json['conferenceId'],
        'date': DateTimeRangeDtoFromJSON(json['date']),
        'companyJobsVisibleWithinApp': DateTimeRangeDtoFromJSON(json['companyJobsVisibleWithinApp']),
        'conferenceModuleVisibleWithinApp': DateTimeRangeDtoFromJSON(json['conferenceModuleVisibleWithinApp']),
        'conferenceArea': json['conferenceArea'],
        'standNumber': json['standNumber'],
    };
}
export function ConferenceToCompanyDtoToJSON(json) {
    return ConferenceToCompanyDtoToJSONTyped(json, false);
}
export function ConferenceToCompanyDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'conferenceId': value['conferenceId'],
        'date': DateTimeRangeDtoToJSON(value['date']),
        'companyJobsVisibleWithinApp': DateTimeRangeDtoToJSON(value['companyJobsVisibleWithinApp']),
        'conferenceModuleVisibleWithinApp': DateTimeRangeDtoToJSON(value['conferenceModuleVisibleWithinApp']),
        'conferenceArea': value['conferenceArea'],
        'standNumber': value['standNumber'],
    };
}
