/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SkillDtoFromJSON, SkillDtoToJSON, } from './SkillDto.js';
/**
 * Check if a given object implements the ListSkillsForInterestsResult interface.
 */
export function instanceOfListSkillsForInterestsResult(value) {
    if (!('skills' in value) || value['skills'] === undefined)
        return false;
    return true;
}
export function ListSkillsForInterestsResultFromJSON(json) {
    return ListSkillsForInterestsResultFromJSONTyped(json, false);
}
export function ListSkillsForInterestsResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'skills': (json['skills'].map(SkillDtoFromJSON)),
    };
}
export function ListSkillsForInterestsResultToJSON(json) {
    return ListSkillsForInterestsResultToJSONTyped(json, false);
}
export function ListSkillsForInterestsResultToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'skills': (value['skills'].map(SkillDtoToJSON)),
    };
}
