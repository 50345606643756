/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the PollDto interface.
 */
export function instanceOfPollDto(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('matchId' in value) || value['matchId'] === undefined)
        return false;
    if (!('dates' in value) || value['dates'] === undefined)
        return false;
    return true;
}
export function PollDtoFromJSON(json) {
    return PollDtoFromJSONTyped(json, false);
}
export function PollDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'matchId': json['matchId'],
        'dates': json['dates'],
        'selectedDate': json['selectedDate'] == null ? undefined : (new Date(json['selectedDate'])),
        'answeredAt': json['answeredAt'] == null ? undefined : (new Date(json['answeredAt'])),
    };
}
export function PollDtoToJSON(json) {
    return PollDtoToJSONTyped(json, false);
}
export function PollDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'matchId': value['matchId'],
        'dates': value['dates'],
        'selectedDate': value['selectedDate'] == null ? undefined : (value['selectedDate'].toISOString()),
        'answeredAt': value['answeredAt'] == null ? undefined : (value['answeredAt'].toISOString()),
    };
}
