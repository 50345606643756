/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { JobLinkDtoFromJSON, JobLinkDtoToJSON, } from './JobLinkDto.js';
import { ChatInfoDtoFromJSON, ChatInfoDtoToJSON, } from './ChatInfoDto.js';
/**
 * Check if a given object implements the MatchChatDto interface.
 */
export function instanceOfMatchChatDto(value) {
    if (!('info' in value) || value['info'] === undefined)
        return false;
    if (!('job' in value) || value['job'] === undefined)
        return false;
    if (!('markedAsUnreadUser' in value) || value['markedAsUnreadUser'] === undefined)
        return false;
    return true;
}
export function MatchChatDtoFromJSON(json) {
    return MatchChatDtoFromJSONTyped(json, false);
}
export function MatchChatDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'info': ChatInfoDtoFromJSON(json['info']),
        'job': JobLinkDtoFromJSON(json['job']),
        'markedAsUnreadUser': json['markedAsUnreadUser'],
    };
}
export function MatchChatDtoToJSON(json) {
    return MatchChatDtoToJSONTyped(json, false);
}
export function MatchChatDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'info': ChatInfoDtoToJSON(value['info']),
        'job': JobLinkDtoToJSON(value['job']),
        'markedAsUnreadUser': value['markedAsUnreadUser'],
    };
}
