/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the GetEmployerOnboardingEmployerDto interface.
 */
export function instanceOfGetEmployerOnboardingEmployerDto(value) {
    if (!('firstName' in value) || value['firstName'] === undefined)
        return false;
    if (!('lastName' in value) || value['lastName'] === undefined)
        return false;
    if (!('email' in value) || value['email'] === undefined)
        return false;
    return true;
}
export function GetEmployerOnboardingEmployerDtoFromJSON(json) {
    return GetEmployerOnboardingEmployerDtoFromJSONTyped(json, false);
}
export function GetEmployerOnboardingEmployerDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
    };
}
export function GetEmployerOnboardingEmployerDtoToJSON(json) {
    return GetEmployerOnboardingEmployerDtoToJSONTyped(json, false);
}
export function GetEmployerOnboardingEmployerDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'email': value['email'],
    };
}
