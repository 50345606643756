/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SkillTypeFromJSON, SkillTypeToJSON, } from './SkillType.js';
/**
 * Check if a given object implements the HardSkillDto interface.
 */
export function instanceOfHardSkillDto(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('type' in value) || value['type'] === undefined)
        return false;
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('tag' in value) || value['tag'] === undefined)
        return false;
    if (!('jobCategoryId' in value) || value['jobCategoryId'] === undefined)
        return false;
    return true;
}
export function HardSkillDtoFromJSON(json) {
    return HardSkillDtoFromJSONTyped(json, false);
}
export function HardSkillDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'type': SkillTypeFromJSON(json['type']),
        'name': json['name'],
        'tag': json['tag'],
        'jobCategoryId': json['jobCategoryId'],
    };
}
export function HardSkillDtoToJSON(json) {
    return HardSkillDtoToJSONTyped(json, false);
}
export function HardSkillDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'type': SkillTypeToJSON(value['type']),
        'name': value['name'],
        'tag': value['tag'],
        'jobCategoryId': value['jobCategoryId'],
    };
}
