<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useToast } from 'vue-toastification'
import { useAuthStore } from '@/store'
import { AuthState } from '@/store/auth'
import type { ActionType } from '@/types/action-type'

const authStore = useAuthStore()

const { authState } = storeToRefs(authStore)

const router = useRouter()
const toast = useToast()
const t = useI18n()

interface AuthStateRenderOptions {
  title: string
  message: string
  action?: ActionType<() => Promise<void>>
}

const renderOptions = computed((): AuthStateRenderOptions => {
  switch (authState.value) {
    case AuthState.Initializing:
      return {
        title: t.t('auth.state.initializing.title'),
        message: t.t('auth.state.initializing.message'),
      }
    case AuthState.ExpiredToken:
      return {
        title: t.t('auth.state.expiredToken.title'),
        message: t.t('auth.state.expiredToken.message'),
        action: {
          label: t.t('auth.state.expiredToken.action'),
          action: async () => {
            const success = await authStore.requestRefreshToken()
            if (!success) {
              toast.error(t.t('auth.state.expiredToken.error'))
              await authStore.logout()
            } else {
              toast.success(t.t('auth.state.expiredToken.success'))
            }

            await router.push({
              name: '/login',
            })
          },
        },
      }
    case AuthState.InvalidToken:
      return {
        title: t.t('auth.state.invalidToken.title'),
        message: t.t('auth.state.invalidToken.message'),
        action: {
          label: t.t('auth.state.invalidToken.action'),
          action: async () => {
            if (!await authStore.isAuthenticated()) {
              return
            }

            await authStore.logout()
          },
        },
      }
    case AuthState.UnexpectedError:
      return {
        title: t.t('auth.state.unexpectedError.title'),
        message: t.t('auth.state.unexpectedError.message'),
        action: {
          label: t.t('auth.state.unexpectedError.action'),
          action: async () => {
            await router.push({
              name: '/',
            })
          },
        },
      }
    case AuthState.AuthenticationError:
      return {
        title: t.t('auth.state.authenticationError.title'),
        message: t.t('auth.state.authenticationError.message'),
        action: {
          label: t.t('auth.state.authenticationError.action'),
          action: async () => {
            await router.push({
              name: '/login',
            })
          },
        },
      }
    case AuthState.ServerNotReachable:
      return {
        title: t.t('auth.state.serverNotReachable.title'),
        message: t.t('auth.state.serverNotReachable.message'),
        action: {
          label: t.t('auth.state.serverNotReachable.action'),
          action: async () => {
            router.go(0)
          },
        },
      }
    default:
      return {
        title: '',
        message: '',
      }
  }
})
</script>

<template>
  <AuthLayoutCard>
    <template #title>
      {{ renderOptions.title }}
    </template>

    <template #message>
      {{ renderOptions.message }}
    </template>

    <ProgressBar v-if="authState === AuthState.Initializing" class="w-100%" mode="indeterminate" style="height: 6px" />

    <Button v-if="renderOptions.action" v-click-async="renderOptions.action.action" v-bind="renderOptions.action" class="w-50" rounded />
  </AuthLayoutCard>
</template>
