/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const PermissionResource = {
    Dashboard: 'Dashboard',
    CompanyProfile: 'CompanyProfile',
    CompanySettings: 'CompanySettings',
    CompanyBilling: 'CompanyBilling',
    Job: 'Job',
    JobApplication: 'JobApplication',
    Scout: 'Scout',
    Match: 'Match',
    Full: 'Full',
    CustomerOnboarding: 'CustomerOnboarding',
    ConferenceOnboarding: 'ConferenceOnboarding',
    ConferenceSwipe: 'ConferenceSwipe',
    Lead: 'Lead'
};
export function instanceOfPermissionResource(value) {
    for (const key in PermissionResource) {
        if (Object.prototype.hasOwnProperty.call(PermissionResource, key)) {
            if (PermissionResource[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function PermissionResourceFromJSON(json) {
    return PermissionResourceFromJSONTyped(json, false);
}
export function PermissionResourceFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function PermissionResourceToJSON(value) {
    return value;
}
export function PermissionResourceToJSONTyped(value, ignoreDiscriminator) {
    return value;
}
