/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the JobLinkDto interface.
 */
export function instanceOfJobLinkDto(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('title' in value) || value['title'] === undefined)
        return false;
    if (!('subtitle' in value) || value['subtitle'] === undefined)
        return false;
    return true;
}
export function JobLinkDtoFromJSON(json) {
    return JobLinkDtoFromJSONTyped(json, false);
}
export function JobLinkDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'title': json['title'],
        'subtitle': json['subtitle'],
    };
}
export function JobLinkDtoToJSON(json) {
    return JobLinkDtoToJSONTyped(json, false);
}
export function JobLinkDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'title': value['title'],
        'subtitle': value['subtitle'],
    };
}
