/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the CreateEmployerResponse interface.
 */
export function instanceOfCreateEmployerResponse(value) {
    if (!('token' in value) || value['token'] === undefined)
        return false;
    return true;
}
export function CreateEmployerResponseFromJSON(json) {
    return CreateEmployerResponseFromJSONTyped(json, false);
}
export function CreateEmployerResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'companyId': json['companyId'] == null ? undefined : json['companyId'],
        'token': json['token'],
    };
}
export function CreateEmployerResponseToJSON(json) {
    return CreateEmployerResponseToJSONTyped(json, false);
}
export function CreateEmployerResponseToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'companyId': value['companyId'],
        'token': value['token'],
    };
}
