/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { JobFunctionCrudJobFunctionDtoFromJSON, JobFunctionCrudJobFunctionDtoToJSON, } from './JobFunctionCrudJobFunctionDto.js';
/**
 * Check if a given object implements the JobFunctionCrudListResult interface.
 */
export function instanceOfJobFunctionCrudListResult(value) {
    if (!('jobFunctions' in value) || value['jobFunctions'] === undefined)
        return false;
    return true;
}
export function JobFunctionCrudListResultFromJSON(json) {
    return JobFunctionCrudListResultFromJSONTyped(json, false);
}
export function JobFunctionCrudListResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'jobFunctions': (json['jobFunctions'].map(JobFunctionCrudJobFunctionDtoFromJSON)),
    };
}
export function JobFunctionCrudListResultToJSON(json) {
    return JobFunctionCrudListResultToJSONTyped(json, false);
}
export function JobFunctionCrudListResultToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'jobFunctions': (value['jobFunctions'].map(JobFunctionCrudJobFunctionDtoToJSON)),
    };
}
