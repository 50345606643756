<script setup lang="ts">
definePage({
  name: '/auth',
  meta: {
    allowAnonymous: true,
    layout: 'auth',
  },
})
</script>

<template>
  <router-view v-slot="{ Component }">
    <suspense>
      <component :is="Component" />
    </suspense>
  </router-view>
</template>
