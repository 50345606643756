/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GetAutoCompleteLocationsLocationFromJSON, GetAutoCompleteLocationsLocationToJSON, } from './GetAutoCompleteLocationsLocation.js';
/**
 * Check if a given object implements the GetAutoCompleteLocationsResult interface.
 */
export function instanceOfGetAutoCompleteLocationsResult(value) {
    if (!('sessionToken' in value) || value['sessionToken'] === undefined)
        return false;
    if (!('locations' in value) || value['locations'] === undefined)
        return false;
    return true;
}
export function GetAutoCompleteLocationsResultFromJSON(json) {
    return GetAutoCompleteLocationsResultFromJSONTyped(json, false);
}
export function GetAutoCompleteLocationsResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'sessionToken': json['sessionToken'],
        'locations': (json['locations'].map(GetAutoCompleteLocationsLocationFromJSON)),
    };
}
export function GetAutoCompleteLocationsResultToJSON(json) {
    return GetAutoCompleteLocationsResultToJSONTyped(json, false);
}
export function GetAutoCompleteLocationsResultToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'sessionToken': value['sessionToken'],
        'locations': (value['locations'].map(GetAutoCompleteLocationsLocationToJSON)),
    };
}
