/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { JobLinkDtoFromJSON, JobLinkDtoToJSON, } from './JobLinkDto.js';
import { ChatInfoDtoFromJSON, ChatInfoDtoToJSON, } from './ChatInfoDto.js';
/**
 * Check if a given object implements the ConferenceChatDto interface.
 */
export function instanceOfConferenceChatDto(value) {
    if (!('info' in value) || value['info'] === undefined)
        return false;
    return true;
}
export function ConferenceChatDtoFromJSON(json) {
    return ConferenceChatDtoFromJSONTyped(json, false);
}
export function ConferenceChatDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'info': ChatInfoDtoFromJSON(json['info']),
        'job': json['job'] == null ? undefined : JobLinkDtoFromJSON(json['job']),
    };
}
export function ConferenceChatDtoToJSON(json) {
    return ConferenceChatDtoToJSONTyped(json, false);
}
export function ConferenceChatDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'info': ChatInfoDtoToJSON(value['info']),
        'job': JobLinkDtoToJSON(value['job']),
    };
}
