/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const CharacteristicColor = {
    Red: 'Red',
    Yellow: 'Yellow',
    Green: 'Green',
    Blue: 'Blue'
};
export function instanceOfCharacteristicColor(value) {
    for (const key in CharacteristicColor) {
        if (Object.prototype.hasOwnProperty.call(CharacteristicColor, key)) {
            if (CharacteristicColor[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function CharacteristicColorFromJSON(json) {
    return CharacteristicColorFromJSONTyped(json, false);
}
export function CharacteristicColorFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function CharacteristicColorToJSON(value) {
    return value;
}
export function CharacteristicColorToJSONTyped(value, ignoreDiscriminator) {
    return value;
}
