/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GetJobApplicationAnonymousUserFromJSON, GetJobApplicationAnonymousUserToJSON, } from './GetJobApplicationAnonymousUser.js';
/**
 * Check if a given object implements the FindBestCandidateResult interface.
 */
export function instanceOfFindBestCandidateResult(value) {
    if (!('matchScoreId' in value) || value['matchScoreId'] === undefined)
        return false;
    if (!('user' in value) || value['user'] === undefined)
        return false;
    if (!('matchScoreTotal' in value) || value['matchScoreTotal'] === undefined)
        return false;
    return true;
}
export function FindBestCandidateResultFromJSON(json) {
    return FindBestCandidateResultFromJSONTyped(json, false);
}
export function FindBestCandidateResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'matchScoreId': json['matchScoreId'],
        'user': GetJobApplicationAnonymousUserFromJSON(json['user']),
        'matchScoreTotal': json['matchScoreTotal'],
    };
}
export function FindBestCandidateResultToJSON(json) {
    return FindBestCandidateResultToJSONTyped(json, false);
}
export function FindBestCandidateResultToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'matchScoreId': value['matchScoreId'],
        'user': GetJobApplicationAnonymousUserToJSON(value['user']),
        'matchScoreTotal': value['matchScoreTotal'],
    };
}
