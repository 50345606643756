/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const ReportingPeriodicity = {
    None: 'None',
    Daily: 'Daily',
    Weekly: 'Weekly'
};
export function instanceOfReportingPeriodicity(value) {
    for (const key in ReportingPeriodicity) {
        if (Object.prototype.hasOwnProperty.call(ReportingPeriodicity, key)) {
            if (ReportingPeriodicity[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function ReportingPeriodicityFromJSON(json) {
    return ReportingPeriodicityFromJSONTyped(json, false);
}
export function ReportingPeriodicityFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ReportingPeriodicityToJSON(value) {
    return value;
}
export function ReportingPeriodicityToJSONTyped(value, ignoreDiscriminator) {
    return value;
}
