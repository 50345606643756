/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PermissionLevelFromJSON, PermissionLevelToJSON, } from './PermissionLevel.js';
import { PermissionResourceFromJSON, PermissionResourceToJSON, } from './PermissionResource.js';
/**
 * Check if a given object implements the PermissionDto interface.
 */
export function instanceOfPermissionDto(value) {
    if (!('resource' in value) || value['resource'] === undefined)
        return false;
    if (!('level' in value) || value['level'] === undefined)
        return false;
    return true;
}
export function PermissionDtoFromJSON(json) {
    return PermissionDtoFromJSONTyped(json, false);
}
export function PermissionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'resource': PermissionResourceFromJSON(json['resource']),
        'level': PermissionLevelFromJSON(json['level']),
    };
}
export function PermissionDtoToJSON(json) {
    return PermissionDtoToJSONTyped(json, false);
}
export function PermissionDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'resource': PermissionResourceToJSON(value['resource']),
        'level': PermissionLevelToJSON(value['level']),
    };
}
