import { useLocalStorage } from '@vueuse/core'
import { useLogger } from '@/modules/logger'

/**
 * Authentication provider.
 * Used to identify the authentication provider.
 *
 * The `Unknown` provider is used when the provider is not known.
 * The `None` provider is used when no provider is set. aka logged out.
 */
export enum AuthProvider {
  Unknown = 'unknown',
  None = 'none',
  Msal = 'msal',
  Firebase = 'firebase',
  Jwt = 'jwt',
}

/**
 * Abstract class for authentication services.
 * Used to create a common interface for different authentication services.
 *
 * Use the AuthStore to use the active service.
 *
 * The generic service methods that can be used are:
 * isAuthenticated, getAccessToken, logout
 *
 * For specific service methods use the authStore.getActiveService<T>(type) method.
 * This will always return the requested service and sets the active service in the store.
 * Usage for example in the login, register, or redirect login methods. To initialize the service.
 *
 * For provider specific methods like changePassword, etc. that for example only work with Firebase.
 * Use the route meta to protect that route for a specific provider.
 * Usage: meta:
 *  requiredAuthProvider: 'firebase'
 *
 */
export abstract class AuthService<TUser, TClient> {
  abstract authProvider: AuthProvider

  protected abstract user: () => TUser | null | Promise<TUser | null>
  protected authClient: TClient

  protected accessToken: string | null = null

  constructor(authClient: TClient) {
    this.authClient = authClient
  }

  /**
   * Initialize the authentication service.
   */
  abstract init(): Promise<void>

  /**
   * Check if the user is authenticated.
   *
   * @returns true if the user is authenticated.
   */
  abstract isAuthenticated(): Promise<boolean>

  /**
   * Get the access token from the service.
   *
   * @returns The access token or null if not found.
   */
  abstract getAccessToken(): Promise<string | null>

  /**
   * Logout the user from the service. And remove the token from the storage.
   *
   * @returns true if the user is logged out.
   */
  abstract logout(): Promise<boolean>

  static logger = useLogger('auth-service')

  /**
   * Set the auth provider in the local storage.
   */
  static setAuthProviderInCache = (provider: AuthProvider) => useLocalStorage<AuthProvider>('auth-provider', provider).value = provider

  /**
   * Get the auth provider from the local storage.
   */
  static getAuthProviderFromCache = async () => useLocalStorage<AuthProvider>('auth-provider', await this.getAuthProviderInLocalStorage()).value

  /**
   * Make sure that feat is backwards compatible, check if an access token is stored in the storage.
   * If not already set as auth-provider in the local storage
   */
  static async getAuthProviderInLocalStorage(): Promise<AuthProvider> {
    if (isMsalInCache()) {
      this.logger.debug('MSAL token found in cache')

      return AuthProvider.Msal
    }

    if (isJwtInCache()) {
      this.logger.debug('JWT token found in cache')

      return AuthProvider.Jwt
    }

    // check lastly if firebase is in cache, since its an promise
    if (await isFirebaseInCache()) {
      this.logger.debug('Firebase token found in cache')
      return AuthProvider.Firebase
    }

    this.logger.debug('No token found in cache')

    return AuthProvider.None
  }
}

/**
 * Check if JWT is in the storage cache.
 * For Backwards compatibility.
 */
function isJwtInCache() {
  const token = localStorage.getItem('jwt:accessToken')

  return !!token
}

/**
 * Check if Firebase is in the storage cache.
 * For Backwards compatibility.
 */
async function isFirebaseInCache() {
  const firebaseApiKey = import.meta.env.VITE_APP_FIREBASE_API_KEY || ''

  // Open a connection to the IndexedDB for firebaseLocalStorage
  const request = window.indexedDB?.open('firebaseLocalStorageDb')

  if (!request) {
    // If IndexedDB is not supported or open fails, return false
    return false
  }

  // We need to await the result of the open request, but IndexedDB uses events
  return new Promise((resolve) => {
    request.onsuccess = () => {
      const db = request.result
      // The key used by firebase to store the user data in the IndexedDB
      const dbKey = `firebase:authUser:${firebaseApiKey}:[DEFAULT]`

      const transaction = db.transaction(['firebaseLocalStorage'], 'readonly')
      const objectStore = transaction.objectStore('firebaseLocalStorage')
      const dbRequest = objectStore.get(dbKey)

      dbRequest.onsuccess = () => {
        const result = dbRequest.result
        if (result) {
          resolve(true)
        } else {
          resolve(false)
        }
      }
    }

    request.onerror = () => {
      resolve(false)
    }
  })
}

/**
 * Check if MSAL is in the storage cache.
 * For Backwards compatibility.
 */
function isMsalInCache() {
  const clientId = import.meta.env.VITE_AZURE_AD_CLIENT_ID || ''

  // msal.token.keys.{clientId} is the key used by msal to store the token
  const storage = localStorage.getItem(`msal.token.keys.${clientId}`)
  if (!storage) {
    return false
  }

  // in storage (is here so editor does not complain about simplifying the if statement; cleaner code)
  return true
}
