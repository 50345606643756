/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ListCreditTransactionsCreditTransactionDto interface.
 */
export function instanceOfListCreditTransactionsCreditTransactionDto(value) {
    if (!('createdAt' in value) || value['createdAt'] === undefined)
        return false;
    if (!('creditAmount' in value) || value['creditAmount'] === undefined)
        return false;
    if (!('creditPrice' in value) || value['creditPrice'] === undefined)
        return false;
    if (!('totalPrice' in value) || value['totalPrice'] === undefined)
        return false;
    return true;
}
export function ListCreditTransactionsCreditTransactionDtoFromJSON(json) {
    return ListCreditTransactionsCreditTransactionDtoFromJSONTyped(json, false);
}
export function ListCreditTransactionsCreditTransactionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'createdAt': (new Date(json['createdAt'])),
        'creditAmount': json['creditAmount'],
        'creditPrice': json['creditPrice'],
        'totalPrice': json['totalPrice'],
    };
}
export function ListCreditTransactionsCreditTransactionDtoToJSON(json) {
    return ListCreditTransactionsCreditTransactionDtoToJSONTyped(json, false);
}
export function ListCreditTransactionsCreditTransactionDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'createdAt': ((value['createdAt']).toISOString()),
        'creditAmount': value['creditAmount'],
        'creditPrice': value['creditPrice'],
        'totalPrice': value['totalPrice'],
    };
}
