import type {
  CharacteristicColor,
  GetEmployerProfileResult,
  HardSkillDto,
  Item,
  JobCategoryCrudJobCategoryDto,
  ListPersonalCharacteristicsPersonalCharacteristicDto,
  NullableRangeDto,
  PersonalityTypeDto,
  SkillDto,
  UpdateEmployerProfileCommandDto,
} from '@swipe4work/api-client-fetch'

import { defineStore } from 'pinia'
import posthog from 'posthog-js'
import { captureException } from '@sentry/vue'
import { AuthState, useAuthStore } from '@/store/auth'

/** The general store contains general entities used throughout the application, like Education Levels, skills etc */
export const useGeneralStore = defineStore('general', {
  state: () => ({
    _educationLevels: [] as Item[],
    _personalCharacteristics: [] as ListPersonalCharacteristicsPersonalCharacteristicDto[],
    _allHardSkills: [] as HardSkillDto[],
    softSkills: [] as SkillDto[],
    languages: [] as SkillDto[],
    _personalityTypes: [] as PersonalityTypeDto[],
    newJobReasons: [] as Item[],
    jobCategories: [] as JobCategoryCrudJobCategoryDto[],
    employeeCountOptions: [
      {
        id: { lower: 1, upper: 10 } as NullableRangeDto,
        name: '1-10',
      },
      {
        id: { lower: 10, upper: 50 } as NullableRangeDto,
        name: '10-50',
      },
      {
        id: { lower: 50, upper: 100 } as NullableRangeDto,
        name: '50-100',
      },
      {
        id: { lower: 100, upper: 1000 } as NullableRangeDto,
        name: '100-1000',
      },
      {
        id: { lower: 1000 } as NullableRangeDto,
        name: '1000+',
      },
    ],
    profile: undefined as GetEmployerProfileResult | undefined,
    authStore: useAuthStore(),
  }),
  getters: {
    isOriginalCompany: (state) => {
      return state.profile?.originalCompanyId === undefined || state.profile?.companyId === state.profile?.originalCompanyId
    },
    personalCharacteristic:
      state =>
        (id: number): string => {
          return state._personalCharacteristics?.find(item => item.id === id)?.name ?? ''
        },
    personalCharacteristics:
      state =>
        (ids: number[]): string => {
          return state._personalCharacteristics
            .filter(p => ids.includes(p.id))
            .map(p => p.name)
            .join(', ')
        },
    personalCharacteristicsOfColor:
      state =>
        (color: CharacteristicColor): string =>
          state._personalCharacteristics
            .filter(p => p.color === color)
            .map(p => p.name)
            .join(', ')
            .toLocaleLowerCase(),
    educationLevel: state => (id: number) => {
      return state._educationLevels?.find(item => item.id === id)?.name ?? ''
    },
    newJobReason: state => (id: number) => {
      return state.newJobReasons?.find(item => item.id === id)?.name ?? ''
    },
    jobCategory: state => (id: number) => {
      return state.jobCategories?.find(item => item.id === id)?.name ?? ''
    },
    jobFunction: state => (jobCategoryId: number, id: number) => {
      return state.jobCategories?.find(item => item.id === jobCategoryId)?.jobFunctions.find(i => i.id === id)?.name ?? ''
    },
    personalityType:
      state =>
        (id: number): PersonalityTypeDto => {
          return state._personalityTypes.find(p => p.id === id) ?? state._personalityTypes[state._personalityTypes.length - 1]
        },
    hasSubscription: state => state.profile?.hasSubscription,
  },
  actions: {
    // Get the skills except from the top skills
    normalSkills(skills: SkillDto[], topSkills: SkillDto[]) {
      const normalSkills = skills.filter(s => !topSkills.map(ts => ts.id).includes(s.id))
      return normalSkills
    },
    hardSkills(jobCategoryId: number): SkillDto[] {
      return (
        this._allHardSkills
          .filter(s => s.jobCategoryId === jobCategoryId)
          // Map to SkillDto to make sure equality works
          .map((s) => {
            return { id: s.id, tag: s.tag, name: s.name, type: s.type }
          })
      )
    },
    otherSkills(skills: SkillDto[], jobSkills: SkillDto[]): SkillDto[] {
      return skills.filter(skill => !jobSkills.map(p => p.id).includes(skill.id)).sort((a, b) => (a.name > b.name ? -1 : 1))
    },
    jobSkillsMatched(skills: SkillDto[], jobSkills: SkillDto[]): SkillDto[] {
      return jobSkills.filter(jobSkill => skills.map(s => s.id).includes(jobSkill.id)).sort((a, b) => (a.name > b.name ? -1 : 1))
    },
    jobSkillsNotMatched(skills: SkillDto[], jobSkills: SkillDto[]): SkillDto[] {
      return jobSkills.filter(jobSkill => !skills.map(s => s.id).includes(jobSkill.id)).sort((a, b) => (a.name > b.name ? -1 : 1))
    },
    async fill(): Promise<void> {
      try {
        await Promise.all([
          this.listPersonalCharacteristics(),
          this.listEducationLevels(),
          this.listJobCategories(),
          this.listNewJobReasons(),
          this.listSkills(),
          this.listPersonalityTypes(),
        ])
      } catch (error) {
        captureException(error)
        this.authStore.setAuthState(AuthState.UnexpectedError)
      }
    },
    async updateEmployerProfile(employerProfile: UpdateEmployerProfileCommandDto) {
      await this.http.employerApi.updateProfile(employerProfile)
      return true
    },
    async fetchProfile() {
      try {
        const profileResponse = await this.http.employerApi.getProfile()
        this.profile = profileResponse
        posthog.identify(this.profile.externalId)
      } catch (error) {
        captureException(error)

        this.authStore.setAuthState(AuthState.UnexpectedError)
      }
      return this.profile
    },
    async logout() {
      this.profile = undefined
      posthog.reset()
    },
    async listPersonalCharacteristics(): Promise<Item[]> {
      if (this._personalCharacteristics.length === 0) {
        const response = await this.http.generalApi.listPersonalCharacteristics()
        this._personalCharacteristics = response.personalCharacteristics
      }
      return this._personalCharacteristics
    },
    async listEducationLevels(): Promise<Item[]> {
      if (this._educationLevels.length === 0) {
        const response = await this.http.generalApi.listEducationLevels()
        this._educationLevels = response.educationLevels
      }
      return this._educationLevels
    },
    async listJobCategories(): Promise<JobCategoryCrudJobCategoryDto[]> {
      if (this.jobCategories.length === 0) {
        const response = await this.http.generalApi.listJobCategories()
        this.jobCategories = response.jobCategories
      }
      return this.jobCategories
    },
    async listNewJobReasons(): Promise<Item[]> {
      if (this.newJobReasons.length === 0) {
        const response = await this.http.generalApi.listNewJobReasons()
        this.newJobReasons = response.newJobReasons
      }
      return this.newJobReasons
    },
    async listSkills() {
      const response = await this.http.employerApi.listAllSkills()
      this._allHardSkills = response.hardSkills
      this.softSkills = response.softSkills
      this.languages = response.languages
    },
    async listPersonalityTypes(): Promise<PersonalityTypeDto[]> {
      const response = await this.http.generalApi.listPersonalityTypes()
      this._personalityTypes = response.personalityTypes
      return this._personalityTypes
    },
  },
})
