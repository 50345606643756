/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ImageDto interface.
 */
export function instanceOfImageDto(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('fileName' in value) || value['fileName'] === undefined)
        return false;
    if (!('url' in value) || value['url'] === undefined)
        return false;
    return true;
}
export function ImageDtoFromJSON(json) {
    return ImageDtoFromJSONTyped(json, false);
}
export function ImageDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'fileName': json['fileName'],
        'url': json['url'],
    };
}
export function ImageDtoToJSON(json) {
    return ImageDtoToJSONTyped(json, false);
}
export function ImageDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'fileName': value['fileName'],
        'url': value['url'],
    };
}
