/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ListAllWaitingJobApplicationsJobDto interface.
 */
export function instanceOfListAllWaitingJobApplicationsJobDto(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('title' in value) || value['title'] === undefined)
        return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined)
        return false;
    if (!('jobCategoryId' in value) || value['jobCategoryId'] === undefined)
        return false;
    if (!('jobFunctionId' in value) || value['jobFunctionId'] === undefined)
        return false;
    return true;
}
export function ListAllWaitingJobApplicationsJobDtoFromJSON(json) {
    return ListAllWaitingJobApplicationsJobDtoFromJSONTyped(json, false);
}
export function ListAllWaitingJobApplicationsJobDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'title': json['title'],
        'createdAt': (new Date(json['createdAt'])),
        'jobCategoryId': json['jobCategoryId'],
        'jobFunctionId': json['jobFunctionId'],
    };
}
export function ListAllWaitingJobApplicationsJobDtoToJSON(json) {
    return ListAllWaitingJobApplicationsJobDtoToJSONTyped(json, false);
}
export function ListAllWaitingJobApplicationsJobDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'title': value['title'],
        'createdAt': ((value['createdAt']).toISOString()),
        'jobCategoryId': value['jobCategoryId'],
        'jobFunctionId': value['jobFunctionId'],
    };
}
