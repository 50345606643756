<script setup lang="ts">
definePage({
  name: '/auth-state',
  path: '/auth-info',
  meta: {
    isAuthRedirect: true,
    allowAnonymous: true,
  },
})
</script>

<template>
  <AuthStateOverlay />
</template>
