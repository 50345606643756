import type { GetCompanyStatisticsResult, ListUnhandledJobsJobDto } from '@swipe4work/api-client-fetch'
import { defineStore } from 'pinia'
import { useAuthStore } from './auth'

export const useDashboardStore = defineStore('dashboard', {
  state: () => ({
    authStore: useAuthStore(),
    statistics: undefined as GetCompanyStatisticsResult | undefined,
    unhandledJobs: [] as ListUnhandledJobsJobDto[],
  }),
  getters: {
    // total count of applications unhandled jobs
    unhandledJobApplicationCount(state) {
      return state.unhandledJobs.map(job => job.unhandledJobApplicationCount).reduce((a, b) => a + b, 0)
    },
  },
  actions: {
    async listUnhandledMatches() {
      return await this.http.employerApi.listUnhandledMatches(10, 0)
    },
    async fetchUnhandledJobs(): Promise<ListUnhandledJobsJobDto[]> {
      if (!this.authStore.hasReadPermission('Job')) {
        return []
      }
      const response = await this.http.employerApi.listUnhandledJobs(10, 0)
      this.unhandledJobs = response.jobs
      return this.unhandledJobs
    },
    async fetchStatistics(): Promise<GetCompanyStatisticsResult> {
      const response = await this.http.employerApi.getStatistics()
      this.statistics = response
      return this.statistics
    },
  },
})
