<script setup lang="ts">
import NProgress from 'nprogress'

import { object, string } from 'zod'
import { storeToRefs } from 'pinia'
import { useForm } from '@/modules/form'
import { useAuthStore } from '@/store'
import { FirebaseAuthService } from '@/services/auth/firebase-auth-service'
import { useConferenceOnboardingStore } from '@/store/conference-onboarding'
import { AuthState } from '@/store/auth'

definePage({
  name: '/conference.onboarding/register',
  path: 'registreren',
  meta: {
    layout: 'false',
  },
})

const conferenceOnboardingStore = useConferenceOnboardingStore()

const { employerOnboarding } = storeToRefs(conferenceOnboardingStore)

const t = useI18n()

const registerModel = reactive({
  employerFirstName: employerOnboarding.value?.invite?.firstName,
  employerLastName: employerOnboarding.value?.invite?.lastName,
  email: employerOnboarding.value?.invite?.email,
  password: '',
  confirmPassword: '',
})

const registerScheme = object({
  employerFirstName: string().min(1, t.t('leads.upgrade.account.firstNameRequired')),
  employerLastName: string().min(1, t.t('leads.upgrade.account.lastNameRequired')),
  email: string().email(t.t('leads.upgrade.account.emailNotValid')),
  password: string().min(8, t.t('leads.upgrade.account.passwordRequired')),
  confirmPassword: string().min(8, t.t('leads.upgrade.account.passwordRequired')),
})

const form = useForm(registerModel, registerScheme)

const passwordsNotEqual = ref(false)
const authStore = useAuthStore()
const router = useRouter()

async function register() {
  const entity = form.validate()
  if (!entity) {
    return
  }
  passwordsNotEqual.value = entity.password !== entity.confirmPassword

  if (passwordsNotEqual.value) {
    return
  }

  NProgress.start()
  const response = await conferenceOnboardingStore.createEmployer(
    {
      firstName: entity.employerFirstName,
      lastName: entity.employerLastName,
      password: entity.password,
    },
  )
  if (response) {
    try {
      await authStore.logout()
      const firebaseAuthService = await authStore.getAuthService<FirebaseAuthService>(FirebaseAuthService, authStore.firebaseAuth)
      await firebaseAuthService.loginWithToken(response.token)
      await authStore.fetchEmployerAccess()
      router.push({ name: '/' })
    } catch (error) {
      authStore.setAuthState(AuthState.AuthenticationError)
    }
  }
  NProgress.done()
}
</script>

<template>
  <form @submit.prevent="register">
    <section class="mb-7.5 max-w-lg space-y-3">
      <h1>
        {{ $t("leads.upgrade.account.title") }}
      </h1>

      <p>
        {{ $t("leads.upgrade.account.subtitle") }}
      </p>
    </section>

    <div class="min-h-[30rem]">
      <div class="mt-2">
        <label for="email"> {{ $t("register.email") }} </label>
        <InputText
          id="email"
          v-model.trim="registerModel.email"
          class="w-100%"
          disabled
          readonly
          autocomplete="email"
        />
        <small v-if="form.hasError('email')" class="color-red-700">{{ form.errorMessage("email") }}</small>
      </div>
      <div class="mt-2">
        <label for="employerFirstName"> {{ $t("settings.firstName") }}* </label>
        <InputText
          id="employerFirstName"
          v-model.trim="registerModel.employerFirstName"
          class="w-100%"
          required="true"
          autofocus
          :class="{ 'p-invalid': form.hasError('registerModel.employerFirstName') }"
        />
        <small v-if="form.hasError('employerFirstName')" class="color-red-700">{{ form.errorMessage("employerFirstName") }}</small>
      </div>
      <div class="mt-2">
        <label for="employerLastName"> {{ $t("settings.lastName") }}* </label>
        <InputText
          id="employerLastName"
          v-model.trim="registerModel.employerLastName"
          class="w-100%"
          required="true"
          :class="{ 'p-invalid': form.hasError('registerModel.employerLastName') }"
        />
        <small v-if="form.hasError('employerLastName')" class="color-red-700">{{ form.errorMessage("employerLastName") }}</small>
      </div>

      <div class="mt-2">
        <label class="block text-sm" for="password"> {{ $t("password") }} </label>
        <Password
          id="password"
          v-model="registerModel.password"
          toggle-mask
          :required="true"
          :feedback="false"
          :input-props="{ autocomplete: 'new-password', autofocus: true }"
          :class="{ 'p-invalid': form.hasError('registerModel.password') }"
        />
        <small v-if="form.hasError('password')" class="block color-red-700">{{ form.errorMessage("password") }}</small>

        <small v-if="passwordsNotEqual" class="block color-red-700">{{ $t("register.passwordsNotEqual") }}</small>
      </div>
      <div class="mt-2">
        <label class="block text-sm" for="confirmPassword"> {{ $t("confirmPassword") }} </label>
        <Password
          id="confirmPassword"
          v-model="registerModel.confirmPassword"
          toggle-mask
          :required="true"
          :feedback="false"
          :input-props="{ autocomplete: 'new-password', autofocus: true }"
          :class="{ 'p-invalid': form.hasError('registerModel.confirmPassword') }"
        />
        <small v-if="form.hasError('confirmPassword')" class="block color-red-700">{{ form.errorMessage("confirmPassword") }}</small>
        <small v-if="passwordsNotEqual" class="block color-red-700">{{ $t("register.passwordsNotEqual") }}</small>
      </div>

      <Button
        v-click-async="register"
        rounded
        class="mt-10"
        :label="$t('register.submit')"
      />
    </div>
  </form>
</template>
