<script setup lang="ts">
const version = ref(import.meta.env.VITE_APP_VERSION)
</script>

<template>
  <footer class="layout-footer mt-4 flex flex flex-col items-center justify-center pt-4">
    <small class="text-center space-x-2">
      <span>© {{ new Date().getFullYear() }}</span>
      <a href="https://swipe4work.com/werkgevers/" target="_blank">Swipe4Work</a>
      <span>|</span>
      <span>{{ version }} </span>
      <br>
    </small>
  </footer>
</template>

<style scoped lang="scss">
@import '@/sass/_variables';

.layout-footer {
  transition: margin-left $transitionDuration;
  border-top: 1px solid var(--surface-border);
}
</style>
