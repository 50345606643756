/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ListUnhandledJobsJobDto interface.
 */
export function instanceOfListUnhandledJobsJobDto(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined)
        return false;
    if (!('title' in value) || value['title'] === undefined)
        return false;
    if (!('functionTitle' in value) || value['functionTitle'] === undefined)
        return false;
    if (!('unhandledJobApplicationCount' in value) || value['unhandledJobApplicationCount'] === undefined)
        return false;
    if (!('lastApplicationAt' in value) || value['lastApplicationAt'] === undefined)
        return false;
    return true;
}
export function ListUnhandledJobsJobDtoFromJSON(json) {
    return ListUnhandledJobsJobDtoFromJSONTyped(json, false);
}
export function ListUnhandledJobsJobDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'title': json['title'],
        'functionTitle': json['functionTitle'],
        'unhandledJobApplicationCount': json['unhandledJobApplicationCount'],
        'lastApplicationAt': (new Date(json['lastApplicationAt'])),
    };
}
export function ListUnhandledJobsJobDtoToJSON(json) {
    return ListUnhandledJobsJobDtoToJSONTyped(json, false);
}
export function ListUnhandledJobsJobDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'createdAt': ((value['createdAt']).toISOString()),
        'title': value['title'],
        'functionTitle': value['functionTitle'],
        'unhandledJobApplicationCount': value['unhandledJobApplicationCount'],
        'lastApplicationAt': ((value['lastApplicationAt']).toISOString()),
    };
}
