/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreateCompanyForEmployerInvoiceDtoFromJSON, CreateCompanyForEmployerInvoiceDtoToJSON, } from './CreateCompanyForEmployerInvoiceDto.js';
import { CreateCompanyForEmployerCompanyDtoFromJSON, CreateCompanyForEmployerCompanyDtoToJSON, } from './CreateCompanyForEmployerCompanyDto.js';
/**
 * Check if a given object implements the CreateCompanyForEmployerCommandDto interface.
 */
export function instanceOfCreateCompanyForEmployerCommandDto(value) {
    if (!('company' in value) || value['company'] === undefined)
        return false;
    if (!('hasAcceptedTermsAndConditions' in value) || value['hasAcceptedTermsAndConditions'] === undefined)
        return false;
    if (!('invoice' in value) || value['invoice'] === undefined)
        return false;
    return true;
}
export function CreateCompanyForEmployerCommandDtoFromJSON(json) {
    return CreateCompanyForEmployerCommandDtoFromJSONTyped(json, false);
}
export function CreateCompanyForEmployerCommandDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'company': CreateCompanyForEmployerCompanyDtoFromJSON(json['company']),
        'subscriptionId': json['subscriptionId'] == null ? undefined : json['subscriptionId'],
        'creditPackageId': json['creditPackageId'] == null ? undefined : json['creditPackageId'],
        'hasAcceptedTermsAndConditions': json['hasAcceptedTermsAndConditions'],
        'invoice': CreateCompanyForEmployerInvoiceDtoFromJSON(json['invoice']),
    };
}
export function CreateCompanyForEmployerCommandDtoToJSON(json) {
    return CreateCompanyForEmployerCommandDtoToJSONTyped(json, false);
}
export function CreateCompanyForEmployerCommandDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'company': CreateCompanyForEmployerCompanyDtoToJSON(value['company']),
        'subscriptionId': value['subscriptionId'],
        'creditPackageId': value['creditPackageId'],
        'hasAcceptedTermsAndConditions': value['hasAcceptedTermsAndConditions'],
        'invoice': CreateCompanyForEmployerInvoiceDtoToJSON(value['invoice']),
    };
}
