<template>
  <Card>
    <template #content>
      <div class="flex flex-col items-center gap-y-7.5">
        <section class="flex flex-col items-center gap-y-5 text-center">
          <h1>
            <slot name="title" />
          </h1>

          <p class="max-w-lg leading-tight !px-5">
            <slot name="message" />
          </p>
        </section>

        <slot />
      </div>
    </template>
  </Card>
</template>
