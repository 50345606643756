/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the BillingPeriodicityType interface.
 */
export function instanceOfBillingPeriodicityType(value) {
    if (!('unit' in value) || value['unit'] === undefined)
        return false;
    if (!('period' in value) || value['period'] === undefined)
        return false;
    return true;
}
export function BillingPeriodicityTypeFromJSON(json) {
    return BillingPeriodicityTypeFromJSONTyped(json, false);
}
export function BillingPeriodicityTypeFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'unit': json['unit'],
        'period': json['period'],
    };
}
export function BillingPeriodicityTypeToJSON(json) {
    return BillingPeriodicityTypeToJSONTyped(json, false);
}
export function BillingPeriodicityTypeToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'unit': value['unit'],
        'period': value['period'],
    };
}
