<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useJobApplicationStore } from './job-applications/job-application-store'

definePage({
  name: '/job-applications.[jobApplicationId]',
  meta: {
    permissionResource: 'JobApplication',
  },
})

const route = useRoute('/job-applications.[jobApplicationId]')
const jobApplicationId = Number.parseInt(route.params.jobApplicationId)

const jobApplicationStore = useJobApplicationStore()

onUnmounted(() => {
  jobApplicationStore.clearDetail()
})
const { jobApplicationNotFound } = storeToRefs(jobApplicationStore)
</script>

<template>
  <not-found v-if="!jobApplicationId || jobApplicationNotFound" />
  <loading-wrapper v-else>
    <JobApplicationDetail :job-application-id="jobApplicationId" />
  </loading-wrapper>
</template>
