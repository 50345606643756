/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the GetCompanyStatisticsResult interface.
 */
export function instanceOfGetCompanyStatisticsResult(value) {
    if (!('waitingJobApplicationCount' in value) || value['waitingJobApplicationCount'] === undefined)
        return false;
    if (!('totalJobApplicationCount' in value) || value['totalJobApplicationCount'] === undefined)
        return false;
    if (!('publishedJobCount' in value) || value['publishedJobCount'] === undefined)
        return false;
    if (!('unhandledMatchCount' in value) || value['unhandledMatchCount'] === undefined)
        return false;
    if (!('totalMatchCount' in value) || value['totalMatchCount'] === undefined)
        return false;
    return true;
}
export function GetCompanyStatisticsResultFromJSON(json) {
    return GetCompanyStatisticsResultFromJSONTyped(json, false);
}
export function GetCompanyStatisticsResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'waitingJobApplicationCount': json['waitingJobApplicationCount'],
        'totalJobApplicationCount': json['totalJobApplicationCount'],
        'publishedJobCount': json['publishedJobCount'],
        'unhandledMatchCount': json['unhandledMatchCount'],
        'totalMatchCount': json['totalMatchCount'],
    };
}
export function GetCompanyStatisticsResultToJSON(json) {
    return GetCompanyStatisticsResultToJSONTyped(json, false);
}
export function GetCompanyStatisticsResultToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'waitingJobApplicationCount': value['waitingJobApplicationCount'],
        'totalJobApplicationCount': value['totalJobApplicationCount'],
        'publishedJobCount': value['publishedJobCount'],
        'unhandledMatchCount': value['unhandledMatchCount'],
        'totalMatchCount': value['totalMatchCount'],
    };
}
