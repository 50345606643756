/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const SubscriptionStatus = {
    Active: 'active',
    Deactivated: 'deactivated'
};
export function instanceOfSubscriptionStatus(value) {
    for (const key in SubscriptionStatus) {
        if (Object.prototype.hasOwnProperty.call(SubscriptionStatus, key)) {
            if (SubscriptionStatus[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function SubscriptionStatusFromJSON(json) {
    return SubscriptionStatusFromJSONTyped(json, false);
}
export function SubscriptionStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function SubscriptionStatusToJSON(value) {
    return value;
}
export function SubscriptionStatusToJSONTyped(value, ignoreDiscriminator) {
    return value;
}
