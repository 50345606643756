<script setup lang="ts">
import JobForm from './components/JobForm.vue'
import { JobFormAction } from '@/pages/jobs/job-form'

definePage({
  meta: {
    permissionLevel: 'Write',
    permissionResource: 'Job',
  },
})
</script>

<template>
  <JobForm :action="JobFormAction.Create" />
</template>
