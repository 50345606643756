/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AddressDtoFromJSON, AddressDtoToJSON, } from './AddressDto.js';
/**
 * Check if a given object implements the CreateCompanyForEmployerInvoiceDto interface.
 */
export function instanceOfCreateCompanyForEmployerInvoiceDto(value) {
    if (!('email' in value) || value['email'] === undefined)
        return false;
    if (!('address' in value) || value['address'] === undefined)
        return false;
    return true;
}
export function CreateCompanyForEmployerInvoiceDtoFromJSON(json) {
    return CreateCompanyForEmployerInvoiceDtoFromJSONTyped(json, false);
}
export function CreateCompanyForEmployerInvoiceDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'email': json['email'],
        'address': AddressDtoFromJSON(json['address']),
    };
}
export function CreateCompanyForEmployerInvoiceDtoToJSON(json) {
    return CreateCompanyForEmployerInvoiceDtoToJSONTyped(json, false);
}
export function CreateCompanyForEmployerInvoiceDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'email': value['email'],
        'address': AddressDtoToJSON(value['address']),
    };
}
