import { createPinia } from 'pinia'
import { useRouter } from 'vue-router'
import type { App, Plugin } from 'vue'
import { useToast } from 'vue-toastification'
import type { HttpClients } from '@/modules/http-clients'
import { useEmployerApi, useGeneralApi } from '@/modules/http-clients'
import { i18n } from '@/modules/i18n'

declare module 'pinia' {
  export interface PiniaCustomProperties {
    http: HttpClients
    router: ReturnType<typeof useRouter>
    t: ReturnType<typeof useI18n>
    toast: ReturnType<typeof useToast>
  }
}

const pinia = createPinia()

pinia.use(({ store }) => {
  store.http = {
    generalApi: useGeneralApi(),
    employerApi: useEmployerApi(),
  }

  store.router = useRouter()
  store.t = i18n.global
  store.toast = useToast()
})

export default {
  install(app: App) {
    app.use(pinia)
  },
} as Plugin
