/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SortedImageDtoFromJSON, SortedImageDtoToJSON, } from './SortedImageDto.js';
import { NullableRangeDtoFromJSON, NullableRangeDtoToJSON, } from './NullableRangeDto.js';
import { ImageDtoFromJSON, ImageDtoToJSON, } from './ImageDto.js';
import { PointDtoFromJSON, PointDtoToJSON, } from './PointDto.js';
/**
 * Check if a given object implements the UpdateCompanyCommandDto interface.
 */
export function instanceOfUpdateCompanyCommandDto(value) {
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('locationName' in value) || value['locationName'] === undefined)
        return false;
    if (!('geolocation' in value) || value['geolocation'] === undefined)
        return false;
    if (!('aboutUs' in value) || value['aboutUs'] === undefined)
        return false;
    if (!('culture' in value) || value['culture'] === undefined)
        return false;
    if (!('employeeCount' in value) || value['employeeCount'] === undefined)
        return false;
    if (!('employeeStory' in value) || value['employeeStory'] === undefined)
        return false;
    if (!('employeeName' in value) || value['employeeName'] === undefined)
        return false;
    if (!('employeeFunction' in value) || value['employeeFunction'] === undefined)
        return false;
    if (!('generalImages' in value) || value['generalImages'] === undefined)
        return false;
    return true;
}
export function UpdateCompanyCommandDtoFromJSON(json) {
    return UpdateCompanyCommandDtoFromJSONTyped(json, false);
}
export function UpdateCompanyCommandDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'name': json['name'],
        'locationName': json['locationName'],
        'geolocation': PointDtoFromJSON(json['geolocation']),
        'aboutUs': json['aboutUs'],
        'culture': json['culture'],
        'employeeCount': NullableRangeDtoFromJSON(json['employeeCount']),
        'employeeStory': json['employeeStory'],
        'employeeName': json['employeeName'],
        'employeeFunction': json['employeeFunction'],
        'logo': json['logo'] == null ? undefined : ImageDtoFromJSON(json['logo']),
        'cover': json['cover'] == null ? undefined : ImageDtoFromJSON(json['cover']),
        'employeeProfilePicture': json['employeeProfilePicture'] == null ? undefined : ImageDtoFromJSON(json['employeeProfilePicture']),
        'generalImages': (json['generalImages'].map(SortedImageDtoFromJSON)),
    };
}
export function UpdateCompanyCommandDtoToJSON(json) {
    return UpdateCompanyCommandDtoToJSONTyped(json, false);
}
export function UpdateCompanyCommandDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'name': value['name'],
        'locationName': value['locationName'],
        'geolocation': PointDtoToJSON(value['geolocation']),
        'aboutUs': value['aboutUs'],
        'culture': value['culture'],
        'employeeCount': NullableRangeDtoToJSON(value['employeeCount']),
        'employeeStory': value['employeeStory'],
        'employeeName': value['employeeName'],
        'employeeFunction': value['employeeFunction'],
        'logo': ImageDtoToJSON(value['logo']),
        'cover': ImageDtoToJSON(value['cover']),
        'employeeProfilePicture': ImageDtoToJSON(value['employeeProfilePicture']),
        'generalImages': (value['generalImages'].map(SortedImageDtoToJSON)),
    };
}
