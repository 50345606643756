<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useCurrentUser } from 'vuefire'
import * as Sentry from '@sentry/vue'
import { useAuthStore } from '@/store'
import { AuthState } from '@/store/auth'
import Auth from '@/layouts/auth.vue'
import AuthInfoState from '@/pages/auth/AuthInfoState.vue'

const authStore = useAuthStore()
const { authState } = storeToRefs(authStore)
const user = useCurrentUser()

// we don't need this watcher on server
onMounted(() => {
  watch(user, (user, prevUser) => {
    if (user && !prevUser) {
      Sentry.setUser({ id: user.uid ?? undefined })
    }

    if (!user) {
      Sentry.setUser(null)
    }
  })
})

useHead({
  title: 'Swipe4Work',
  meta: [
    {
      name: 'description',
      content: 'Swipe4Work werkgeversportaal',
    },
  ],
})

const route = useRoute()

const showAuthOverlay = computed(() => {
  if (route.meta.layout === 'auth') {
    return false
  }

  return authState.value !== AuthState.Inactive
})
</script>

<template>
  <div v-if="showAuthOverlay">
    <Auth>
      <AuthInfoState />
    </Auth>
  </div>
  <template v-else>
    <suspense>
      <router-view />
    </suspense>
  </template>
  <ConfirmDialog />
</template>

<style lang="scss">
@import 'App.scss';
</style>
