// handle-click-async.ts (Vue 3 directive)
import type { Directive, DirectiveBinding } from 'vue'

export type ClickAsyncHandler = (() => void) | (() => Promise<void>)

/**
 * Directive to handle async click events
 *
 * So that the button is disabled while the async function is running, it will always be re-enabled after the function is done
 */
const clickAsync: Directive = {
  mounted(el, binding: DirectiveBinding<ClickAsyncHandler>) {
    el.addEventListener('click', async () => {
      if (el.getAttribute('disabled')) {
        return
      }

      el.setAttribute('disabled', 'disabled')

      try {
        await binding.value()
      } finally {
        el.removeAttribute('disabled')
      }
    })
  },
}

export default clickAsync
