import { PermissionLevel } from '@swipe4work/api-client-fetch'
import type { RouteLocationNormalized } from 'vue-router'
import type { GuardResult } from '@/guards/abstract-guard'
import { Guard, GuardResultType } from '@/guards/abstract-guard'
import { useAuthStore } from '@/store'

export default class AuthorizationPermissionGuard extends Guard {
  protected label: string = 'AuthorizationPermissionGuard'

  protected async check(to: RouteLocationNormalized): Promise<GuardResult | void> {
    const allowedRoles = to.meta.allowedRoles ?? []
    const employerRoles = this.authStore.employerAccess?.roles ?? []
    if (allowedRoles.length > 0 && !allowedRoles.some(role => employerRoles.includes(role))) {
      this.logger.warn(`User does not have the required role to access ${to.path}`)

      return {
        name: '/',
      }
    }

    const permissionResource = to.meta.permissionResource
    if (!permissionResource) {
      return GuardResultType.Next
    }

    const authStore = useAuthStore()
    await authStore.getEmployerAccess()

    const hasReadPermission = authStore.hasReadPermission(permissionResource)
    const hasWritePermission = authStore.hasWritePermission(permissionResource)

    const permissionLevel = to.meta.permissionLevel ?? PermissionLevel.Read

    const hasPermission = permissionLevel === PermissionLevel.Read ? hasReadPermission : hasWritePermission
    if (!hasPermission) {
      this.logger.warn(`User does not have permission to access ${to.meta.permissionResource}`)

      return {
        name: '/',
      }
    }
  }
}
