import { defineStore } from 'pinia'
import type { HubConnectionState } from '@microsoft/signalr'
import * as signalR from '@microsoft/signalr'
import { useAuthStore } from '@/store/auth'

const getAccessToken = async () => {
  const authStore = useAuthStore()
  return await authStore.getAccessToken()
}

interface HubConnectionService {
  init: () => Promise<void>
  start: () => Promise<void>
  stop: () => Promise<void>
  connection: signalR.HubConnection | undefined
  hasConnection: () => boolean
  on: (methodName: string, method: (...args: any[]) => void) => void
  invoke: <T>(methodName: string, ...args: any[]) => Promise<T>
}

/**
 * Hub store
 *
 * Should be defined here to avoid circular dependency
 */
export const useHubStore = defineStore('hub-connections', {
  state: () => ({
    _chatHubConnection: undefined as signalR.HubConnection | undefined,
  }),
  actions: {
    isPending(state: HubConnectionState) {
      return state === signalR.HubConnectionState.Connecting || state === signalR.HubConnectionState.Reconnecting || state === signalR.HubConnectionState.Disconnecting
    },
    chatHub(): HubConnectionService {
      const connection = this._chatHubConnection

      return {
        init: async () => {
          if (this._chatHubConnection) {
            return
          }

          this._chatHubConnection = new signalR.HubConnectionBuilder()
            .configureLogging(import.meta.env.PROD ? signalR.LogLevel.Warning : (import.meta.env.VITE_APP_SIGNALR_LOG_LEVEL ? import.meta.env.VITE_APP_SIGNALR_LOG_LEVEL : signalR.LogLevel.Trace))
            .withUrl(`${import.meta.env.VITE_APP_API_URL}/hubs/chat`, {
              accessTokenFactory: async () => {
                return await getAccessToken() || ''
              },
            })
            .withAutomaticReconnect([500, 1000, 2000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000])
            .build()
        },
        start: async () => {
          if (!this._chatHubConnection) {
            return
          }

          if (this._chatHubConnection.state === signalR.HubConnectionState.Connected) {
            return
          }

          await this._chatHubConnection.start()
        },
        stop: async () => {
          if (!this._chatHubConnection || this._chatHubConnection.state === signalR.HubConnectionState.Disconnected) {
            return
          }

          await this._chatHubConnection.stop()
        },
        get connection() {
          return connection as signalR.HubConnection | undefined
        },
        hasConnection: () => {
          return this._chatHubConnection !== undefined
        },
        on: (methodName, method) => {
          if (!this._chatHubConnection) {
            return
          }

          this._chatHubConnection.off(methodName)

          this._chatHubConnection.on(methodName, method)
        },
        invoke: async (methodName, ...args) => {
          if (!this._chatHubConnection) {
            throw new Error('Connection is not initialized')
          }

          return await this._chatHubConnection.invoke(methodName, ...args)
        },
      }
    },
  },
})
