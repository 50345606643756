/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ListMatchesForJobMatchDtoFromJSON, ListMatchesForJobMatchDtoToJSON, } from './ListMatchesForJobMatchDto.js';
/**
 * Check if a given object implements the ListMatchesForJobResult interface.
 */
export function instanceOfListMatchesForJobResult(value) {
    if (!('jobId' in value) || value['jobId'] === undefined)
        return false;
    if (!('matches' in value) || value['matches'] === undefined)
        return false;
    return true;
}
export function ListMatchesForJobResultFromJSON(json) {
    return ListMatchesForJobResultFromJSONTyped(json, false);
}
export function ListMatchesForJobResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'jobId': json['jobId'],
        'matches': (json['matches'].map(ListMatchesForJobMatchDtoFromJSON)),
    };
}
export function ListMatchesForJobResultToJSON(json) {
    return ListMatchesForJobResultToJSONTyped(json, false);
}
export function ListMatchesForJobResultToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'jobId': value['jobId'],
        'matches': (value['matches'].map(ListMatchesForJobMatchDtoToJSON)),
    };
}
