<script setup lang="ts">
import { useSettingsStore } from './settings/settings-store'
import { useTopbarStore } from '@/components/app/topbar-store'

definePage({
  path: '/instellingen',
  meta: {
    permissionResource: 'CompanySettings',
  },
  redirect: { name: '/settings/UserProfile' },
})

const t = useI18n()

const topbarStore = useTopbarStore()
topbarStore.setAndClear({
  breadcrumbs: [{ label: t.t('settings.title') }],
})

const settingsStore = useSettingsStore()
settingsStore.fetchEmployersInCompany()

const items = [
  { label: t.t('settings.profile'), to: { name: '/settings/UserProfile' } },
  { label: t.t('settings.account'), to: { name: '/settings/Account' } },
  { label: t.t('settings.teammates.title'), to: { name: '/settings/Teammates' } },
  { label: t.t('settings.defaultMessages.title', 2), to: { name: '/settings/DefaultMessages' } },
  { label: t.t('termsAndConditions.title'), to: { name: '/settings/TermsAndConditions' } },
]
</script>

<template>
  <div class="flex flex-row justify-center">
    <div class="card max-w-5xl flex-1">
      <TabMenu :model="items">
        <template #item="{ item }">
          <router-link class="p-menuitem-link" :to="item.to!">
            <span class="mr-1">{{ item.label }}</span>
          </router-link>
        </template>
      </TabMenu>
      <div class="pt-5">
        <router-view v-slot="{ Component }">
          <keep-alive>
            <component :is="Component" />
          </keep-alive>
        </router-view>
      </div>
    </div>
  </div>
</template>
