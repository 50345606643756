/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EmployerInviteTypeFromJSON, EmployerInviteTypeToJSON, } from './EmployerInviteType.js';
/**
 * Check if a given object implements the GetEmployerOnboardingEmployerInviteDto interface.
 */
export function instanceOfGetEmployerOnboardingEmployerInviteDto(value) {
    if (!('type' in value) || value['type'] === undefined)
        return false;
    if (!('firstName' in value) || value['firstName'] === undefined)
        return false;
    if (!('lastName' in value) || value['lastName'] === undefined)
        return false;
    if (!('email' in value) || value['email'] === undefined)
        return false;
    if (!('invitedBy' in value) || value['invitedBy'] === undefined)
        return false;
    if (!('invitedByCompany' in value) || value['invitedByCompany'] === undefined)
        return false;
    return true;
}
export function GetEmployerOnboardingEmployerInviteDtoFromJSON(json) {
    return GetEmployerOnboardingEmployerInviteDtoFromJSONTyped(json, false);
}
export function GetEmployerOnboardingEmployerInviteDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'type': EmployerInviteTypeFromJSON(json['type']),
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'invitedBy': json['invitedBy'],
        'invitedByCompany': json['invitedByCompany'],
    };
}
export function GetEmployerOnboardingEmployerInviteDtoToJSON(json) {
    return GetEmployerOnboardingEmployerInviteDtoToJSONTyped(json, false);
}
export function GetEmployerOnboardingEmployerInviteDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'type': EmployerInviteTypeToJSON(value['type']),
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'email': value['email'],
        'invitedBy': value['invitedBy'],
        'invitedByCompany': value['invitedByCompany'],
    };
}
