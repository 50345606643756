<script setup lang="ts">
definePage({
  redirect: '/upgrade/account',
  meta: {
    permissionResource: 'Lead',
  },
})
</script>

<template>
  <div />
</template>
