import type { GetAutoCompleteLocationsLocation, GetLocationDetailsResult } from '@swipe4work/api-client-fetch'
import { defineStore } from 'pinia'

// general is the name of the store. It is unique across your application
// and will appear in devtools
export const useLocationStore = defineStore('location', {
  // a function that returns a fresh state
  state: () => ({
    _cachedLocations: new Map<string, GetLocationDetailsResult>(),
    _sessionToken: undefined as string | undefined,
    autoCompleteLocations: [] as GetAutoCompleteLocationsLocation[],
  }),
  actions: {
    async getAutoCompleteLocations(locationQuery: string) {
      if (locationQuery.length < 3) {
        return null
      }

      try {
        const response = await this.http.generalApi.getAutoCompleteLocations(locationQuery, this._sessionToken)
        this.autoCompleteLocations = response.locations
        this._sessionToken = response.sessionToken
      } catch (e) {
        this.toast.warning(this.t.t('locations.fetchFailed'))
        throw e
      }
    },
    async getLocationDetails(placeId: string): Promise<GetLocationDetailsResult | null> {
      this.autoCompleteLocations = []
      const cachedGeocodedLocation = this._cachedLocations.get(placeId)
      if (cachedGeocodedLocation !== undefined) {
        return cachedGeocodedLocation
      }

      if (this._sessionToken === undefined) {
        this.toast.error(this.t.t('locations.fetchFailed'))
        throw new Error('no session token')
      }
      try {
        const response = await this.http.generalApi.getLocationDetails(this._sessionToken, placeId)
        this._cachedLocations.set(placeId, response)
        return response
      } catch (e) {
        this.toast.error(this.t.t('locations.fetchFailed'))
        throw e
      }
    },
  },
})
