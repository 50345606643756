/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GetMatchChatWithMessagesMatchContactInfoDtoFromJSON, GetMatchChatWithMessagesMatchContactInfoDtoToJSON, } from './GetMatchChatWithMessagesMatchContactInfoDto.js';
import { ChatMessageDtoFromJSON, ChatMessageDtoToJSON, } from './ChatMessageDto.js';
import { GetMatchChatWithMessagesMatchStateDtoFromJSON, GetMatchChatWithMessagesMatchStateDtoToJSON, } from './GetMatchChatWithMessagesMatchStateDto.js';
import { MatchChatDtoFromJSON, MatchChatDtoToJSON, } from './MatchChatDto.js';
/**
 * Check if a given object implements the GetMatchChatWithMessagesResult interface.
 */
export function instanceOfGetMatchChatWithMessagesResult(value) {
    if (!('contactInfo' in value) || value['contactInfo'] === undefined)
        return false;
    if (!('state' in value) || value['state'] === undefined)
        return false;
    if (!('chat' in value) || value['chat'] === undefined)
        return false;
    if (!('messages' in value) || value['messages'] === undefined)
        return false;
    return true;
}
export function GetMatchChatWithMessagesResultFromJSON(json) {
    return GetMatchChatWithMessagesResultFromJSONTyped(json, false);
}
export function GetMatchChatWithMessagesResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'contactInfo': GetMatchChatWithMessagesMatchContactInfoDtoFromJSON(json['contactInfo']),
        'state': GetMatchChatWithMessagesMatchStateDtoFromJSON(json['state']),
        'chat': MatchChatDtoFromJSON(json['chat']),
        'messages': (json['messages'].map(ChatMessageDtoFromJSON)),
    };
}
export function GetMatchChatWithMessagesResultToJSON(json) {
    return GetMatchChatWithMessagesResultToJSONTyped(json, false);
}
export function GetMatchChatWithMessagesResultToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'contactInfo': GetMatchChatWithMessagesMatchContactInfoDtoToJSON(value['contactInfo']),
        'state': GetMatchChatWithMessagesMatchStateDtoToJSON(value['state']),
        'chat': MatchChatDtoToJSON(value['chat']),
        'messages': (value['messages'].map(ChatMessageDtoToJSON)),
    };
}
