/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ListPaymentOptionsSubscriptionDto interface.
 */
export function instanceOfListPaymentOptionsSubscriptionDto(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('title' in value) || value['title'] === undefined)
        return false;
    if (!('price' in value) || value['price'] === undefined)
        return false;
    if (!('billingPeriodMonths' in value) || value['billingPeriodMonths'] === undefined)
        return false;
    if (!('features' in value) || value['features'] === undefined)
        return false;
    if (!('discountFraction' in value) || value['discountFraction'] === undefined)
        return false;
    if (!('includesVideo' in value) || value['includesVideo'] === undefined)
        return false;
    return true;
}
export function ListPaymentOptionsSubscriptionDtoFromJSON(json) {
    return ListPaymentOptionsSubscriptionDtoFromJSONTyped(json, false);
}
export function ListPaymentOptionsSubscriptionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'title': json['title'],
        'price': json['price'],
        'billingPeriodMonths': json['billingPeriodMonths'],
        'features': json['features'],
        'discountFraction': json['discountFraction'],
        'includesVideo': json['includesVideo'],
        'jobSlots': json['jobSlots'] == null ? undefined : json['jobSlots'],
    };
}
export function ListPaymentOptionsSubscriptionDtoToJSON(json) {
    return ListPaymentOptionsSubscriptionDtoToJSONTyped(json, false);
}
export function ListPaymentOptionsSubscriptionDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'title': value['title'],
        'price': value['price'],
        'billingPeriodMonths': value['billingPeriodMonths'],
        'features': value['features'],
        'discountFraction': value['discountFraction'],
        'includesVideo': value['includesVideo'],
        'jobSlots': value['jobSlots'],
    };
}
