/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { JobApplicationStateFromJSON, JobApplicationStateToJSON, } from './JobApplicationState.js';
import { ListAllWaitingJobApplicationsJobDtoFromJSON, ListAllWaitingJobApplicationsJobDtoToJSON, } from './ListAllWaitingJobApplicationsJobDto.js';
import { ListAllWaitingJobApplicationsUserDtoFromJSON, ListAllWaitingJobApplicationsUserDtoToJSON, } from './ListAllWaitingJobApplicationsUserDto.js';
/**
 * Check if a given object implements the ListAllWaitingJobApplicationsJobApplication interface.
 */
export function instanceOfListAllWaitingJobApplicationsJobApplication(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('job' in value) || value['job'] === undefined)
        return false;
    if (!('status' in value) || value['status'] === undefined)
        return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined)
        return false;
    if (!('user' in value) || value['user'] === undefined)
        return false;
    if (!('matchScoreTotal' in value) || value['matchScoreTotal'] === undefined)
        return false;
    return true;
}
export function ListAllWaitingJobApplicationsJobApplicationFromJSON(json) {
    return ListAllWaitingJobApplicationsJobApplicationFromJSONTyped(json, false);
}
export function ListAllWaitingJobApplicationsJobApplicationFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'job': ListAllWaitingJobApplicationsJobDtoFromJSON(json['job']),
        'status': JobApplicationStateFromJSON(json['status']),
        'createdAt': (new Date(json['createdAt'])),
        'user': ListAllWaitingJobApplicationsUserDtoFromJSON(json['user']),
        'matchScoreTotal': json['matchScoreTotal'],
    };
}
export function ListAllWaitingJobApplicationsJobApplicationToJSON(json) {
    return ListAllWaitingJobApplicationsJobApplicationToJSONTyped(json, false);
}
export function ListAllWaitingJobApplicationsJobApplicationToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'job': ListAllWaitingJobApplicationsJobDtoToJSON(value['job']),
        'status': JobApplicationStateToJSON(value['status']),
        'createdAt': ((value['createdAt']).toISOString()),
        'user': ListAllWaitingJobApplicationsUserDtoToJSON(value['user']),
        'matchScoreTotal': value['matchScoreTotal'],
    };
}
