<script setup lang="ts">
import { storeToRefs } from 'pinia'
import type { CreateCompanyForEmployerInvoiceDto } from '@swipe4work/api-client-fetch'
import nProgress from 'nprogress'
import { useUpgradeStore } from './upgrade-store'
import BillingForm from '@/pages/onboarding/components/BillingForm.vue'

definePage({
  path: '/upgrade/facturatie',
  meta: {
    permissionResource: 'Lead',
  },
})

const upgradeStore = useUpgradeStore()
await upgradeStore.fill()

const { selectedPaymentMethod, invoiceModel } = storeToRefs(upgradeStore)

function prevPage() {
  upgradeStore.goToPage(1)
}

if (!selectedPaymentMethod.value) {
  prevPage()
}

const submitting = ref(false)

async function submit(model: CreateCompanyForEmployerInvoiceDto, hasAcceptedTermsAndConditions: boolean) {
  nProgress.start()
  submitting.value = true
  invoiceModel.value = model
  await upgradeStore.convertLeadCompanyToRegular(hasAcceptedTermsAndConditions)
  nProgress.done()
  submitting.value = false
}
</script>

<template>
  <BillingForm :submitting="submitting" @next-page="submit" @prev-page="prevPage" />
</template>
