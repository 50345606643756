import type { RouteLocationNormalized, RouteLocationNormalizedLoaded } from 'vue-router'
import { useAuthStore } from '@/store'
import type { UserModule } from '@/types'
import type { Logger } from '@/modules/logger'
import { useLogger } from '@/modules/logger'
import type { Guard } from '@/guards/abstract-guard'
import { GuardResultType } from '@/guards/abstract-guard'
import RedirectLoginGuard from '@/guards/redirect-login-guard'
import AuthenticationGuard from '@/guards/authentication-guard'
import AuthorizationAccountTypeGuard from '@/guards/authorization-account-type-guard'
import AuthorizationPermissionGuard from '@/guards/authorization-permission-guard'
import { AuthState } from '@/store/auth'

export const install: UserModule = (_, router) => {
  router.beforeResolve(async (to, from) => {
    const authStore = useAuthStore()

    if (authStore.authState !== AuthState.Inactive) {
      return true
    }

    await authStore.init()

    const registeredGuards: (new (
      logger: Logger,
      to: RouteLocationNormalized,
      from: RouteLocationNormalizedLoaded,
      authStore: ReturnType<typeof useAuthStore>
    ) => Guard)[] = [
      RedirectLoginGuard,
      AuthenticationGuard,
      AuthorizationAccountTypeGuard,
      AuthorizationPermissionGuard,
    ]

    for (const GuardType of registeredGuards) {
      const logger = useLogger('auth-guards', GuardType.name)
      const guardInstance = new GuardType(logger, to, from, authStore)
      const result = await guardInstance.resolve()

      switch (result) {
        case GuardResultType.Next:
          continue
        case GuardResultType.Skip:
          return true
        default:
          return result
      }
    }
  })
}
