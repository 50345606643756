import { defineStore } from 'pinia'
import type {
  EmployerRole,
  GetEmployerAccessResult,
  PermissionDto,
  PermissionResource,
} from '@swipe4work/api-client-fetch'
import { PermissionLevel } from '@swipe4work/api-client-fetch'
import { useFirebaseAuth } from 'vuefire'
import posthog from 'posthog-js'
import { useCompanyStore } from './company'
import { AuthProvider, AuthService } from '@/services/auth/auth-service'
import { FirebaseAuthService } from '@/services/auth/firebase-auth-service'
import { MsalAuthService } from '@/services/auth/msal-auth-service'
import { JwtAuthService } from '@/services/auth/jwt-auth-service'
import { useUserStore } from '@/store/user-store'
import { useHubStore } from '@/store/hub-connections'

/**
 * State of the authentication process. For overriding the render of router-view in App.vue, so
 * different states as components can be rendered, like within auth guards or stores, without going through the router.
 *
 * By default, the state is `Inactive`. Meaning the router-view will be shown as normal.
 *
 * Examples given:
 * - showing a loading spinner while initializing MSAL,
 * - when user firebase registration fails
 * - when an error like fetching general data fails
 */
export enum AuthState {
  Inactive = 'inactive',

  Initializing = 'initializing',
  ExpiredToken = 'expiredToken',
  InvalidToken = 'invalidToken',
  AuthenticationError = 'authenticationError',
  UnexpectedError = 'unexpectedError',
  ServerNotReachable = 'serverNotReachable',
}

/**
 * useAuthStore - Authentication Store for Multiple Providers
 *
 * This store is designed to provide a standardized interface for managing authentication across the application, regardless of the authentication provider being used.
 * It supports various authentication services such as Microsoft (MSAL), Google Firebase, or JWT-based authentication.
 *
 * ### Generic Functions
 * The auth store includes generic functions commonly used across the application, such as:
 * - `isAuthenticated`: Checks if the user is currently logged in.
 * - `getAccessToken`: Retrieves the access token for the authenticated user.
 * - `logout`: Logs the user out of the application.
 *
 * These functions are consistently available across all authentication providers. For provider-specific functions, such as Firebase's `loginWithPassword` or `resetPassword`,
 * the relevant actions are handled by the individual authentication services and are not part of the generic interface.
 *
 * ### Provider-Specific Functions
 * Each authentication provider may have its own specific methods, which are not applicable to other providers. For instance, Firebase may offer `resetPassword`, but this does not exist for MSAL or JWT.
 * To access these provider-specific methods, use the `getAuthService<T>` method to retrieve the appropriate service instance. This allows you to call provider-specific functions when needed.
 *
 * ### Dynamic Authentication Service
 * - The `getAuthService<T>` method dynamically retrieves and initializes the appropriate authentication service based on the provider (e.g., FirebaseAuthService, MsalAuthService).
 * - If a provider-specific function is required (such as login, password reset, etc.), this method ensures the correct service is used with the right type for type safety.
 *
 * ### Initialization
 * On initialization, the store checks which authentication provider was last active (cached). Depending on the active provider (Firebase, MSAL, JWT), the corresponding service is initialized.
 *
 * Overall, this store provides a seamless way to manage multiple authentication providers while maintaining a unified interface for core authentication operations.
 */
export const useAuthStore = defineStore('auth', {
  state: () => ({
    firebaseAuth: useFirebaseAuth(),

    authState: AuthState.Inactive as AuthState,
    authError: undefined as Error | undefined,
    authService: null as AuthService<unknown, unknown> | null,
    employerAccess: undefined as GetEmployerAccessResult | undefined,
    userStore: useUserStore(),
    hubStore: useHubStore(),
    companyStore: useCompanyStore(),
  }),
  getters: {
    // If the user has no employer roles, it's considered an employee user
    isEmployeeUser: state => state.employerAccess?.roles.length === 0,
    isRole: state => (role: EmployerRole) => state.employerAccess?.roles.includes(role) ?? false,
    isFirebaseAuth: state => state.authService instanceof FirebaseAuthService,
    isMsalAuth: state => state.authService instanceof MsalAuthService,
    isJwtAuth: state => state.authService instanceof JwtAuthService,
    canAccessOtherCompanies: (state) => {
      return state.employerAccess?.canAccessOtherCompanies ?? false
    },
    permissions: (state) => {
      return state.employerAccess?.permissions ?? []
    },
    hasFullPermissions(): boolean {
      return this.employerAccess?.permissions.map(p => p.resource).includes('Full') ?? false
    },
    isConferenceUser: state => state.employerAccess?.roles.includes('Conference'),
    isLead: state => state.employerAccess?.roles.includes('Lead'),
  },
  actions: {
    async getAuthService<T extends AuthService<unknown, unknown>>(
      Type: { new (authClient: any): T },
      authClient: any = null,
    ): Promise<T> {
      const authService = this.authService
      if (!authService || authService.authProvider !== Type.prototype.authProvider) {
        this.authService = new Type(authClient) // Pass the authClient to the constructor
        AuthService.setAuthProviderInCache(this.authService.authProvider)
        await this.authService.init()
      }

      return this.authService as T
    },
    async init() {
      const activeService = await AuthService.getAuthProviderFromCache()
      if (activeService === AuthProvider.None) {
        return
      }

      switch (activeService) {
        case AuthProvider.Firebase:
          await this.getAuthService(FirebaseAuthService, this.firebaseAuth)
          break
        case AuthProvider.Msal:
          await this.getAuthService(MsalAuthService)
          break
        case AuthProvider.Jwt:
          await this.getAuthService(JwtAuthService)
          // Check if the token is still valid, if so, override the base view with token invalid
          const isTokenValid = JwtAuthService.validateToken(await this.getAccessToken() || '')
          if (isTokenValid !== true) {
            this.setAuthState(isTokenValid)
          }
          break
      }
    },
    hasPermission(requiredPermission?: PermissionDto): boolean {
      if (requiredPermission === undefined) {
        return true
      }
      if (requiredPermission.level === PermissionLevel.Read) {
        return this.hasReadPermission(requiredPermission.resource)
      }
      return this.hasWritePermission(requiredPermission.resource)
    },
    // Has permission to read or write
    hasReadPermission(resource: PermissionResource): boolean {
      if (this.employerAccess === undefined) {
        return false
      }

      if (this.hasFullPermissions) {
        return true
      }

      return this.employerAccess.permissions.map(p => p.resource).includes(resource) ?? false
    },
    // Has permission to write
    hasWritePermission(resource: PermissionResource): boolean {
      if (this.employerAccess === undefined) {
        return false
      }

      if (this.hasFullPermissions) {
        return true
      }

      return this.employerAccess.permissions.find(p => p.resource === resource)?.level === PermissionLevel.Write
    },
    canCreateJob(): boolean {
      return this.hasWritePermission('Job')
    },
    async isAuthenticated() {
      if (!this.authService) {
        return false
      }

      return await this.authService.isAuthenticated()
    },
    async requestRefreshToken() {
      if (!this.authService || this.authService.authProvider !== AuthProvider.Jwt) {
        return false
      }

      const authService = await this.getAuthService(JwtAuthService)

      const token = await authService.getAccessToken()
      if (!token) {
        return false
      }

      await this.http.employerApi.requestLoginLink(token)

      return true
    },
    async getAccessToken() {
      if (!this.authService) {
        return null
      }

      return await this.authService.getAccessToken()
    },
    async getEmployerAccess() {
      if (this.employerAccess) {
        return this.employerAccess
      }

      return await this.fetchEmployerAccess()
    },
    async fetchEmployerAccess() {
      try {
        const roleResponse = await this.http.employerApi.getEmployerAccess()
        this.employerAccess = roleResponse
        posthog.setPersonProperties({
          'is-lead': this.isLead,
        })
      } catch (e) {
        console.error('Error fetching employer access', e)
        this.employerAccess = undefined
      }
      return this.employerAccess
    },
    async logout() {
      await this.userStore.logout()
      // should be under userStore logout too prevent toast issues with undefined profile
      await this.hubStore.chatHub().stop()
      this.employerAccess = undefined

      await this.init()

      if (!this.authService) {
        return
      }

      await this.authService.logout()

      AuthService.setAuthProviderInCache(AuthProvider.None)

      this.authService = null
    },
    setAuthState(state: AuthState) {
      this.authState = state
    },
  },
})
