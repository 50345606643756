/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the GetMatchChatWithMessagesMatchContactInfoDto interface.
 */
export function instanceOfGetMatchChatWithMessagesMatchContactInfoDto(value) {
    if (!('displayName' in value) || value['displayName'] === undefined)
        return false;
    if (!('firstName' in value) || value['firstName'] === undefined)
        return false;
    if (!('phoneNumber' in value) || value['phoneNumber'] === undefined)
        return false;
    if (!('allowPhoneCalls' in value) || value['allowPhoneCalls'] === undefined)
        return false;
    if (!('allowTexts' in value) || value['allowTexts'] === undefined)
        return false;
    if (!('prefersContactOverPhone' in value) || value['prefersContactOverPhone'] === undefined)
        return false;
    return true;
}
export function GetMatchChatWithMessagesMatchContactInfoDtoFromJSON(json) {
    return GetMatchChatWithMessagesMatchContactInfoDtoFromJSONTyped(json, false);
}
export function GetMatchChatWithMessagesMatchContactInfoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'displayName': json['displayName'],
        'firstName': json['firstName'],
        'phoneNumber': json['phoneNumber'],
        'allowPhoneCalls': json['allowPhoneCalls'],
        'allowTexts': json['allowTexts'],
        'prefersContactOverPhone': json['prefersContactOverPhone'],
    };
}
export function GetMatchChatWithMessagesMatchContactInfoDtoToJSON(json) {
    return GetMatchChatWithMessagesMatchContactInfoDtoToJSONTyped(json, false);
}
export function GetMatchChatWithMessagesMatchContactInfoDtoToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'displayName': value['displayName'],
        'firstName': value['firstName'],
        'phoneNumber': value['phoneNumber'],
        'allowPhoneCalls': value['allowPhoneCalls'],
        'allowTexts': value['allowTexts'],
        'prefersContactOverPhone': value['prefersContactOverPhone'],
    };
}
