/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SubscriptionGroupedLinesSpecFromJSON, SubscriptionGroupedLinesSpecToJSON, } from './SubscriptionGroupedLinesSpec.js';
import { SubscriptionStatusFromJSON, SubscriptionStatusToJSON, } from './SubscriptionStatus.js';
import { TotalSpecFromJSON, TotalSpecToJSON, } from './TotalSpec.js';
import { BillingCycleTypeFromJSON, BillingCycleTypeToJSON, } from './BillingCycleType.js';
/**
 * Check if a given object implements the GetSubscriptionResponse interface.
 */
export function instanceOfGetSubscriptionResponse(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('title' in value) || value['title'] === undefined)
        return false;
    if (!('status' in value) || value['status'] === undefined)
        return false;
    if (!('startsOn' in value) || value['startsOn'] === undefined)
        return false;
    if (!('billingCycle' in value) || value['billingCycle'] === undefined)
        return false;
    if (!('groupedLines' in value) || value['groupedLines'] === undefined)
        return false;
    if (!('total' in value) || value['total'] === undefined)
        return false;
    return true;
}
export function GetSubscriptionResponseFromJSON(json) {
    return GetSubscriptionResponseFromJSONTyped(json, false);
}
export function GetSubscriptionResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'title': json['title'],
        'status': SubscriptionStatusFromJSON(json['status']),
        'nextRenewalDate': json['next_renewal_date'] == null ? undefined : (new Date(json['next_renewal_date'])),
        'startsOn': (new Date(json['starts_on'])),
        'endsOn': json['ends_on'] == null ? undefined : (new Date(json['ends_on'])),
        'billingCycle': BillingCycleTypeFromJSON(json['billing_cycle']),
        'groupedLines': (json['grouped_lines'].map(SubscriptionGroupedLinesSpecFromJSON)),
        'total': TotalSpecFromJSON(json['total']),
    };
}
export function GetSubscriptionResponseToJSON(json) {
    return GetSubscriptionResponseToJSONTyped(json, false);
}
export function GetSubscriptionResponseToJSONTyped(value, ignoreDiscriminator = false) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'title': value['title'],
        'status': SubscriptionStatusToJSON(value['status']),
        'next_renewal_date': value['nextRenewalDate'] == null ? undefined : (value['nextRenewalDate'].toISOString()),
        'starts_on': ((value['startsOn']).toISOString()),
        'ends_on': value['endsOn'] == null ? undefined : (value['endsOn'].toISOString()),
        'billing_cycle': BillingCycleTypeToJSON(value['billingCycle']),
        'grouped_lines': (value['groupedLines'].map(SubscriptionGroupedLinesSpecToJSON)),
        'total': TotalSpecToJSON(value['total']),
    };
}
